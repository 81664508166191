import swal from "sweetalert";
import {
    GET_BRANCH_LIST,
} from '../constants';

import {
    getBranch,
} from '../../services/BranchServices';

import * as utility from '../../utility/Utility';

export function getBranchDetails(history) {
    return (dispatch) => {
        getBranch()
            .then((response) => {
                dispatch(getBranchList(response.data.data));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getBranchList(data) {
    return {
        type: GET_BRANCH_LIST,
        payload: data,
    };
}