import axios from "axios";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function getErningList(content_type,payment_status,category_id,board,exam_type,class_no,subject,chapter,transaction_id,page) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  let postData = {
    content_type,
    payment_status,
    category_id,
    board,
    exam_type,
    class_no,
    subject,
    chapter,
    transaction_id,
    page
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_PAYMENT_TRANSACTION_LIST_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function paymentTransactionDetails(assign_content_id) {
    let getData = localStorage.getItem("userDetails");
    let token = JSON.parse(getData).token;
  
    let postData = {
        assign_content_id
    };
  
    return axios({
      url: GlobalConfigs.API_URL + Apis.GET_TRANSACTION_DETAILS_BY_ID_API,
      method: "POST",
      headers: {
        ContentType: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: postData,
    });
  }