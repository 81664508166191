//Upload image
export const POST_UPLOAD_IMAGE_API = "contentdeveloper/uploadimage";

//Check token exist or not
export const CHECK_TOKEN_EXIST_API = "contentdeveloper/checktokenexist";

//Check verified accoint exist or not
export const CHECK_VERIFIED_ACCOUNT_API = "contentdeveloper/checkverifiedornot";

//param - name,email,password,phone,user_type
export const REGISTRATION_API = "contentdeveloper/registration";

//param - email,password
export const LOGIN_API = "contentdeveloper/login";

//param - recid,name,email,phone,profile_pic
export const UPDATE_PROFILE_API = "contentdeveloper/updateprofile";

//param - new_password,old_password
export const CHANGE_PASSWORD_API = "contentdeveloper/updatepassword";

//param - email
export const FORGOT_PASSWORD_API = "contentdeveloper/forgetpassword";

//param - question_zip
export const UPLOAD_QUESTIONS_FILE_API = "contentdeveloper/uploadquestions";

//param - exam_category, exam_type_id, class_id, branch_id, branch_short_code, chapter_id, chapter_short_code, question_type_id, demo_exam, path
export const ADD_QUESTIONS_API = "contentdeveloper/setquestions";

//param - page
export const GET_QUESTIONS_LIST_API = "contentdeveloper/getquestions";

//param - NULL
export const GET_CATEGORY_LIST_API = "contentdeveloper/examcategorylist";

//param - NULL
export const GET_BRANCH_LIST_API = "contentdeveloper/getbranches";

//param - NULL
export const GET_CHAPTER_LIST_API = "contentdeveloper/getchaptersbybranch";

//param - NULL
export const GET_CLASS_LIST_API = "contentdeveloper/getclasses";

//param - 
export const UPLOAD_CONTENT_VALIDATOR_ELIBRARY_API = "contentvalidator/uploadlibrary";

//param - 
export const UPLOAD_CONTENT_LANGUAGE_VALIDATOR_ELIBRARY_API = "contentlanguagevalidator/uploadlibrary";

//param - recid, pdf_file
export const UPLOAD_CONTENT_DESIGNER_PDF_ELIBRARY_API = "contentdesigner/uploadpdf";

//param - 
export const UPLOAD_CONTENT_DESIGNER_CONCEPT_MAP_ELIBRARY_API = "contentdesigner/uploadconceptmap";

//param - 
export const GET_REJECT_REASONS_API = "contentdeveloper/getrejection_reasons_master";

//param - 
export const GET_CONTENT_DEVELOPER_ELIBRARY_API = "contentdeveloper/e-library-list";

//param - 
export const GET_CONTENT_VALIDATOR_ELIBRARY_API = "contentvalidator/e-library-list";

//param - 
export const GET_CONTENT_DESIGNER_ELIBRARY_API = "contentdesigner/e-library-list";

//param - 
export const GET_CONTENT_LANGUAGE_VALIDATOR_ELIBRARY_API = "contentlanguagevalidator/e-library-list";

//param - record_id,approve_status
export const POST_DEVELOPER_CONTENT_APPROVED_AND_REJECT_API = "contentdeveloper/approverejectlibrary";

//param - record_id,approve_status
export const POST_VALIDATOR_CONTENT_APPROVED_AND_REJECT_API = "contentvalidator/approverejectlibrary";

//param - record_id,approve_status
export const POST_LANGUAGE_VALIDATOR_CONTENT_APPROVED_AND_REJECT_API = "contentlanguagevalidator/approverejectlibrary";

//param - record_id,approve_status
export const POST_DESIGNER_CONTENT_APPROVED_AND_REJECT_API = "contentdesigner/approverejectlibrary";


//param - 
export const GET_GETBOARDS_API = "contentdeveloper/getboards";

//param - exam_category
export const GET_EXAMTYPE_API = "contentdeveloper/getexamtype";

//param - exam_category
export const POST_GET_SUBJECT_LIST_API = "contentdeveloper/subjectlist";

//param - 
export const UPLOAD_CONTENT_DEVELOPER_ELIBRARY_API = "contentdeveloper/submitelibrarycontent";

//param - message, password
export const GENERATE_PASSWORD_API = "contentdeveloper/generatepassword";

//param
export const POST_NOTIFICATION_DETAILS_API="common/notification_details"

//param id
export const POST_READ_NOTIFICATION_API="common/read_notification"

//param - page,exam_category_id
export const GET_CONTENT_DEVELOPER_QUESTIONS_API = "contentdeveloper/questions-list";

//param - record_id,approve_status
export const POST_DEVELOPER_CONTENT_APPROVED_AND_REJECT_QUESTIONS_API = "contentdeveloper/approverejectquestion";

//param - record_id,upload_excel
export const UPLOAD_CONTENT_DEVELOPER_QUESTIONS_API = "contentdeveloper/uploadquestions";

//param - record_id,
export const GET_DEVELOPER_QUESTIONS_DETAILS_BY_ID_API = "contentdeveloper/questions_details_byid";

//param - recid,
export const REMOVE_DEVELOPER_QUESTIONS_DETAILS_BY_ID_API = "contentdeveloper/delete_dam_questions";

//param - record_id,
export const ADD_DEVELOPER_QUESTIONS_DETAILS_BY_ID_API = "contentdeveloper/submitquestionsbyid";

//param - record_id,
export const DEVELOPER_ADD_QUESTIONS_API = "contentdeveloper/submitquestionscontent";

//param - NULL
export const GET_QUESTION_TYPE_LIST_API = "common/question_partten_list";

//param - recid
export const GET_QUESTION_DETAILS_BY_ID_API = "contentdeveloper/question_content_byid";

//param - record_id,
export const DEVELOPER_UPDATE_QUESTIONS_API = "contentdeveloper/updatequestionsbyid";

//param - record_id,
export const GET_UPDATE_QUESTIONS_API = "contentdeveloper/updatequestionsbyid";

//param - recid,
export const GENERATE_QUESTION_NUMBER_API = "contentdeveloper/getnewquestionno_bytype";

//param - NULL
export const GET_PAYMENT_TRANSACTION_LIST_API = "common/paymenttrasnction_details";

//param - NULL
export const GET_TRANSACTION_DETAILS_BY_ID_API = "common/get_transaction_details_by_id";

