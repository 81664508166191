import {
    LOADING_BOARD,
    GET_BOARD_LIST
} from '../constants'

const initialState = {
    boardList: [],
    showLoading: false,
};

export function BoardReducer(state = initialState, action) {

    if (action.type === GET_BOARD_LIST) {
        return {
            ...state,
            boardList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === LOADING_BOARD) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    
    return state;
}


