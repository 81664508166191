import {
    GET_ELIBRARY_CONTENT_LIST,
    UPLOAD_CONTENT_DESIGNER_PDF_ELIBRARY,
    ELIBRARY_LOADING,
    APPROVED_REJECT_ELIBRARY_CONTENT_STATUS,
    ELIBRARY_CONTENT_REJECT_REASONS
} from '../constants';

const initialState = {
    contentList: [],
    conceptMaplist: [],
    reasonsList: [],
    totalRecord: 0,
    totalPage: 0,
    showLoading: false,
    filePath:''
};

export function ElibraryReducer(state = initialState, action) {

    if (action.type === GET_ELIBRARY_CONTENT_LIST) {
        return {
            ...state,
            contentList: action.payload.data,
            totalRecord: action.payload.total_record,
            totalPage: action.payload.page_count,
            showLoading: false,
        };
    }

    if (action.type === UPLOAD_CONTENT_DESIGNER_PDF_ELIBRARY) {
        
        return {
            ...state,
            conceptMaplist: action.payload.concept_map_data,
            showLoading: false,
            filePath:action.payload.file_path
        };
    }

    if (action.type === ELIBRARY_CONTENT_REJECT_REASONS) {
        return {
            ...state,
            reasonsList: action.payload.data,
            showLoading: false,
        };
    }

    if (action.type === ELIBRARY_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


