import {
    LOADING_SUBJECT,
    GET_SUBJECT_LIST
} from '../constants'

const initialState = {
    subjectList: [],
    showLoading: false,
};

export function SubjectReducer(state = initialState, action) {

    if (action.type === GET_SUBJECT_LIST) {
        return {
            ...state,
            subjectList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === LOADING_SUBJECT) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


