import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { updateProfileData } from '../../../store/actions/AuthActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

import defoult_profile from "../../../images/demo-user.png";


const Profile = (props) => {

	const fileRef = useRef();

	let errorsObj = { name: '', email: '', phone: '' };

	const [errors, setErrors] = useState(errorsObj);
	const [proImg, setProImg] = useState(props.profile_pic ? props.profile_pic : '');
	const [localImg, setLocalImg] = useState('');
	const [proImgFile, setProImgFile] = useState(null);
	const [name, setName] = useState(props.user_name);
	const [email, setEmail] = useState(props.email);
	const [phone, setPhone] = useState(props.phone_no);

	const dispatch = useDispatch();

	useEffect(() => {

	}, []);

	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		if (name === '') {
			errorObj.name = 'Name is Required';
			error = true;
		}
		if (phone === '') {
			errorObj.phone = 'Phone no. is Required';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(updateProfileData(name, email, phone, proImgFile == null ? '' : proImgFile, props.history));
	}

	const onImageHandle = (event) => {
		let file = event.target.files[0];
		const fr = new FileReader();
		return new Promise((resolve, reject) => {
			fr.addEventListener("load", e => {
				let image = fr.result;
				setLocalImg(image);
				setProImgFile(file);
			});
			fr.addEventListener("error", e => {
				reject();
			});
			fr.readAsDataURL(file);
		});
	}


	return (
		<Fragment>
			<div className="card card-body">
				<div className="row">
					<div className="col-lg-3"></div>
					<div className="col-lg-6">
						<div className="profile-container">

							<div className="profile-header-image">
								{localImg ?
									<img src={localImg} alt="" className="rounded" />
									:
									<img src={proImg ? proImg : defoult_profile} alt="" className="rounded" />
								}
								<div className="profile-custom-file-input">
									<label htmlFor="imgForm"><i className="fa fa-camera m-0" /></label>
									<input
										type="file"
										id="imgForm"
										multiple
										accept="image/*"
										onChange={onImageHandle}
										hidden
									/>
								</div>
							</div>

							<div className="profile-form-content">

								<form onSubmit={onSubmit}>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Name<span className="text-danger">*</span></strong>
										</label>
										<input className="form-control" placeholder="Enter the name" value={name} onChange={(e) => setName(e.target.value)} />
										{errors.name && <div className="text-danger fs-12">{errors.name}</div>}
									</div>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Email</strong>
										</label>
										<input className="form-control email-disable" placeholder="Email" value={email} disabled={true} onChange={(e) => setEmail(e.target.value)} />
									</div>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Phone<span className="text-danger">*</span></strong>
										</label>
										<input className="form-control" placeholder="Enter the phone no." value={phone} onChange={(e) => setPhone(e.target.value)} />
										{errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
									</div>

									<div className="text-center mt-4">
										<button type="submit" className="btn btn-primary btn-block profile-update-btn">Update
											{props.loading ?
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												: null}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-lg-3"></div>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: false,
		email: state.auth.email,
		user_name: state.auth.user_name,
		phone_no: state.auth.phone_no,
		profile_pic: state.auth.profile_pic,
	};
};

export default connect(mapStateToProps)(Profile);