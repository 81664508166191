import React, {
  Fragment,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import * as utility from "../../../utility/Utility";
import * as Apis from "../../../apis/Apis";
import axios from "axios";
import Moment from "react-moment";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import { Editor } from "@tinymce/tinymce-react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { uploadContentDeveloperFile } from "../../../store/actions/ElibraryActions";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["xlsx"];
const pattern = [
  {
    topic_heading: "",
    page_no: "",
  },
];

const ElibraryContentRequestDetails = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();

  let errorsObj = { file: "", content: "", conceptMap: "", citation: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [file, setFile] = useState(null);
  const [hyperlink, setHyperlink] = useState(null);
  const [content, setContent] = useState("");
  const [modalTerms, setModalTerms] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isHyper, setIsHyper] = useState(false);
  const [patternArray, setPatternArray] = useState(
    state?.concept_map_data ? JSON.parse(state.concept_map_data) : pattern
  );
  const [citation, setCitation] = useState(
    state?.citation_no == null || state?.citation_no == undefined
      ? ""
      : parseInt(state?.citation_no)
  );
  const [editorStatus, setEditorStatus] = useState(false);

  useEffect(() => {
    return () => {
      setPatternArray({
        topic_heading: "",
        page_no: "",
      });
      setCitation("");
      setFile(null);
      setContent("");
      setHyperlink(null);
    };
  }, []);

  const onGoBackHandler = () => {
    history.replace({
      pathname: "/content-list",
      state: state?.filterData,
    });
  };

  function onSubmit(e, status) {
    e.preventDefault();

    let error = false;
    let array = true;
    const errorObj = { ...errorsObj };
    if (state.user_type !== 1 && content === "") {
      errorObj.content = "Content is Required";
      error = true;
    }

    if (
      state.user_type !== 1 &&
      (file === "" || file === null) &&
      state?.concept_map == ""
    ) {
      errorObj.file = "Concept Map Required";
      error = true;
    }

    if (
      state.user_type == 1 &&
      file &&
      content &&
      (hyperlink == "" || hyperlink == null)
    ) {
      for (let i = 0; i < patternArray.length; i++) {
        if (
          patternArray[i].topic_heading === "" ||
          patternArray[i].page_no === ""
        ) {
          errorObj.conceptMap = "Hyperlink is Required";
          error = true;
          array = false;
          break;
        }
      }
    } else if (
      state.user_type !== 1 &&
      (hyperlink == "" || hyperlink == null) &&
      state?.hyperlink === ""
    ) {
      for (let i = 0; i < patternArray.length; i++) {
        if (
          patternArray[i].topic_heading === "" ||
          patternArray[i].page_no === ""
        ) {
          errorObj.conceptMap = "Hyperlink is Required";
          error = true;
          array = false;
          break;
        }
      }
    } else {
      for (let i = 0; i < patternArray.length; i++) {
        if (
          (patternArray[i].topic_heading === "" &&
            patternArray[i].page_no !== "") ||
          (patternArray[i].topic_heading !== "" &&
            patternArray[i].page_no == "")
        ) {
          errorObj.conceptMap = "Hyperlink is Required";
          error = true;
          array = false;
          break;
        }
      }
    }

    // console.log(hyperlink, "..Hyperlink..", state?.hyperlink);

    if (state.user_type == 2 && citation === "") {
      errorObj.citation = "No. of Citation is Required";
      error = true;
    } else if (state.user_type == 1 && content && citation === "") {
      errorObj.citation = "No. of Citation is Required";
      error = true;
    }

    // console.log("errorObj......", file[0]);
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      uploadContentDeveloperFile(
        state.id,
        content,
        file ? file[0] : "",
        hyperlink ? hyperlink[0] : "",
        hyperlink ? "[]" : JSON.stringify(patternArray),
        status,
        citation,
        onGoBackHandler,
        props.history
      )
    );
  }

  const handleChange = (file) => {
    setFile(file);
  };

  const hyperlinkChangeHandler = (file) => {
    setHyperlink(file);
  };

  const onImageHandler = (blobInfo) => {
    return new Promise((success, reject) => {
      const formData = new FormData();
      formData.append("content_image", blobInfo.blob());
      axios({
        url: GlobalConfigs.API_URL + Apis.POST_UPLOAD_IMAGE_API,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          success(res.data.imagepath);
        })
        .then((json) => {
          console.log(json);
        });
    });
  };

  const onChangePageNoHandler = (val, index) => {
    let arry = [...patternArray];
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      arry[index].page_no = val;
      if (val) {
        setIsHyper(true);
      } else if (arry[0].topic_heading == "") {
        setIsHyper(false);
      }
      setPatternArray(arry);
    }
  };

  const onChangeTextHandler = (val, index) => {
    let arry = [...patternArray];
    arry[index].topic_heading = val;
    if (val) {
      setIsHyper(true);
    } else if (arry[0].page_no == "") {
      setIsHyper(false);
    }
    setPatternArray(arry);
  };

  const onAddHandler = () => {
    let arry = [...patternArray];
    let obj = {
      topic_heading: "",
      page_no: "",
    };
    arry.push(obj);
    setPatternArray(arry);
  };

  const onAddItem = () => {
    let arry = [...patternArray];
    let status;
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].topic_heading === "" || arry[x].page_no === "") {
        status = true;
      } else {
        status = false;
      }
    }
    return status;
  };

  const onRemoveHandler = (index) => {
    let arry = [...patternArray];
    arry.splice(index, 1);
    setPatternArray(arry);
  };

  const removeUploadFile = () => {
    setFile(null);
  };

  const removeHyperlinkUploadFile = () => {
    setHyperlink(null);
  };

  const onNoOfCitationHandler = (val) => {
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      setCitation(val);
    }
  };

  const handleDownloaFile = (url) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "concept_map_excel.xlsx"); // Optional: Set a default filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getFilenameFromUrl = (url) =>
    url ? url.split("/").pop() : "No data found";

  const getFilename = (url) =>
    url ? url.split("/").pop() : "File Type (.xlsx) and Size (5MB)";

  const copyLinkHandler = (item) => {
    if (item?.pdf_link) {
      navigator.clipboard.writeText(item.pdf_link);
    } else {
      navigator.clipboard.writeText(item.topic_heading + item.page_no);
    }
    utility.showSuccess("Link Copied Successfully");
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="back-arrow-container">
            <Link onClick={onGoBackHandler}>
              <i class="fa-solid fa-arrow-left"></i>
            </Link>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="card content-details">
            <form>
              <div className="row">
                <div className="col-xl-6">
                  <div className="basic-details-item">
                    <label className="w-50">Category </label>
                    <span>{state.exam_category}</span>
                  </div>

                  {state.board_name && (
                    <div className="basic-details-item">
                      <label className="w-50">Board </label>
                      <span>{state.board_name}</span>
                    </div>
                  )}

                  {state.subject && (
                    <div className="basic-details-item">
                      <label className="w-50">Subject </label>
                      <span>{state.subject}</span>
                    </div>
                  )}
                  {state.create_date && (
                    <div className="basic-details-item">
                      <label className="w-50">Create Date </label>
                      <span>
                        <Moment format="DD/MM/YYYY">{state.create_date}</Moment>
                      </span>
                    </div>
                  )}
                  {state.payment != null && (
                    <div className="basic-details-item">
                      <label className="w-50">Amount </label>
                      <span>{state.payment}</span>
                    </div>
                  )}
                </div>

                <div className="col-xl-6">
                  {state.chapter && (
                    <div className="basic-details-item">
                      <label className="w-50">Chapter</label>
                      <span>{state.chapter}</span>
                    </div>
                  )}
                  {state.class && (
                    <div className="basic-details-item">
                      <label className="w-50">Class</label>
                      <span>{state.class}</span>
                    </div>
                  )}
                  {state.exam_type_name && (
                    <div className="basic-details-item">
                      <label className="w-50">Type</label>
                      <span>{state.exam_type_name}</span>
                    </div>
                  )}
                  {state.time_limit && (
                    <div className="basic-details-item">
                      <label className="w-50">Time Limit</label>
                      <span>
                        <Moment format="DD/MM/YYYY">{state.time_limit}</Moment>
                      </span>
                    </div>
                  )}
                  {state.approved_status && (
                    <div className="basic-details-item">
                      <label className="w-50">Status</label>
                      <span>
                        {state.approved_status == 0 ? "Rejected" : "Accepted"}
                      </span>
                    </div>
                  )}
                </div>

                <div className="basic-details-item">
                  <label className="w-50">
                    Write Up
                    <span class="text-danger">
                      {state.user_type == 2 || state.user_type == 3
                        ? "*"
                        : null}
                    </span>
                  </label>

                  <div className="ck-editer-view">
                    <Editor
                      apiKey={GlobalConfigs.TINY_API_KEY}
                      value={content}
                      initialValue={state.content_data}
                      // onInit={(evt, editor) => {
                      //   setText(editor.getContent({ format: 'text' }));
                      // }}
                      onEditorChange={(newValue, editor) => {
                        setEditorStatus(true);
                        setContent(
                          editorStatus
                            ? newValue
                            : state.content_data == null ||
                              state.content_data == undefined
                            ? ""
                            : state.content_data
                        );
                      }}
                      init={{
                        height: 400,
                        menubar: true,
                        image_advtab: true,
                        external_plugins: {
                          tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                        },
                        images_upload_handler: onImageHandler,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "print",
                          "preview anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "paste",
                          "code",
                          "pagebreak",
                          "help",
                          "wordcount",
                          "codesample",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat |pagebreak| help | image |" +
                          "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                        setup: (editor) => {
                          editor.ui.registry.addButton("pagebreak", {
                            text: "Page Break",
                            onAction: function (_) {
                              editor.execCommand(
                                "mceInsertContent",
                                false,
                                '<p style="page-break-before:always;"></p>'
                              );
                            },
                          });
                        },
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                    {errors.content && (
                      <div className="text-danger fs-12">{errors.content}</div>
                    )}
                  </div>
                </div>

                <div className="basic-details-item">
                  <div className="d-flex justify-content-between">
                    <label className="w-50">
                      Concept Map (Before design)
                      <span class="text-danger">
                        {state.user_type == 2 || state.user_type == 3
                          ? "*"
                          : null}
                      </span>
                    </label>
                    <div>
                      {/* <a href={GlobalConfigs.CONCEPT_MAP_TEMPLATE_EXCEL_URL}>
                        Download Template{" "}
                        <i
                          class="fa-solid fa-file-excel fa-xl"
                          style={{ color: "#117D42" }}
                        ></i>
                      </a> */}
                    </div>
                  </div>

                  <div class="file-input-container">
                    <div class="box__input">
                      <FileUploader
                        multiple={true}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        maxSize={5}
                      />
                      <p className="text-center font-10">
                        File Type (.xlsx) and Size (5MB)
                      </p>
                      {file && file[0] && (
                        <p className="text-center">
                          {`File name: ${file[0].name}`}
                        </p>
                      )}
                      <div id="error"></div>
                      <div id="file-name"></div>
                      {file ? (
                        <div
                          className="remove_btn"
                          onClick={() => removeUploadFile()}
                        >
                          Remove
                        </div>
                      ) : null}
                      <p></p>
                    </div>
                    {state?.user_type !== 1 ? (
                      <div className="mb-3 position-relative">
                        <div
                          className="icon_right cursorPointer"
                          onClick={() => handleDownloaFile(state?.concept_map)}
                        >
                          <i class="fa fa-download" aria-hidden="true"></i>
                        </div>
                        <div
                          className="form-control"
                          type="file"
                          onClick={() => handleDownloaFile(state?.concept_map)}
                        >
                          <strong>
                            {getFilenameFromUrl(state?.concept_map)}
                          </strong>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {errors.file && (
                    <div className="text-danger fs-12">{errors.file}</div>
                  )}
                </div>
                {state.user_type !== 3 ? (
                  <div className="basic-details-item">
                    <div className="d-flex justify-content-between">
                      <label className="w-50">
                        Hyperlink
                        <span class="text-danger">
                          {state.user_type == 2 ? "*" : null}
                        </span>
                      </label>
                      <div>
                        <a href={GlobalConfigs.CONCEPT_MAP_TEMPLATE_EXCEL_URL}>
                          Download Template{" "}
                          <i
                            class="fa-solid fa-file-excel fa-xl"
                            style={{ color: "#117D42" }}
                          ></i>
                        </a>
                      </div>
                    </div>

                    <div class="file-input-container">
                      <div class="box__input">
                        <FileUploader
                          disabled={isHyper}
                          multiple={true}
                          handleChange={hyperlinkChangeHandler}
                          name="file"
                          types={fileTypes}
                          maxSize={5}
                        />

                        {/* <p className="text-center">
                          {hyperlink
                            ? `File name: ${hyperlink[0].name}`
                            : getFilename(
                                state?.user_type == 1 ? state?.hyperlink : ""
                              )}
                        </p> */}
                        <p className="text-center font-10">
                          File Type (.xlsx) and Size (5MB)
                        </p>
                        {hyperlink && hyperlink[0] && (
                          <p className="text-center">
                            {`File name: ${hyperlink[0].name}`}
                          </p>
                        )}
                        <div id="error"></div>
                        <div id="file-name"></div>
                        {hyperlink ? (
                          <div
                            className="remove_btn"
                            onClick={() => removeHyperlinkUploadFile()}
                          >
                            Remove
                          </div>
                        ) : null}
                      </div>
                      {state?.user_type !== 1 ? (
                        <div className="mb-3 position-relative">
                          <div
                            className="icon_right cursorPointer"
                            onClick={() => handleDownloaFile(state?.hyperlink)}
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                          </div>
                          <div
                            className="form-control"
                            type="file"
                            onClick={() => handleDownloaFile(state?.hyperlink)}
                          >
                            <strong>
                              {getFilenameFromUrl(state?.hyperlink)}
                            </strong>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* {errors.file && (
                    <div className="text-danger fs-12">{errors.file}</div>
                  )} */}
                  </div>
                ) : null}
                {state.user_type !== 3 ? (
                  <div>
                    <h3 className="text-center">OR</h3>
                  </div>
                ) : null}
                {state.user_type !== 3 ? (
                  <div className="mt-4 mb-4">
                    <ul>
                      {patternArray.map((item, index) => {
                        return (
                          <li className="d-flex mx-0 mb-3">
                            <div className="col-9">
                              <input
                                disabled={
                                  hyperlink || state?.hyperlink ? true : false
                                }
                                type="text"
                                className="form-control"
                                placeholder="Write Something..."
                                defaultValue={item.topic_heading}
                                onChange={(e) =>
                                  onChangeTextHandler(e.target.value, index)
                                }
                              />
                            </div>
                            <div className="col-3 d-flex">
                              <input
                                disabled={
                                  hyperlink || state?.hyperlink ? true : false
                                }
                                className="form-control mx-3 text-center"
                                placeholder="Page no"
                                value={item.page_no}
                                maxLength="3"
                                onChange={(e) =>
                                  onChangePageNoHandler(e.target.value, index)
                                }
                              />
                              {index === 0 ? (
                                <button
                                  type="button"
                                  className="plus-btn px-2"
                                  onClick={onAddHandler}
                                  disabled={
                                    onAddItem() ||
                                    (hyperlink || state?.hyperlink
                                      ? true
                                      : false)
                                  }
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="plus-btn"
                                  onClick={() => onRemoveHandler(index)}
                                  disabled={
                                    hyperlink || state?.hyperlink ? true : false
                                  }
                                >
                                  <i class="fa-solid fa-minus"></i>
                                </button>
                              )}
                              {state?.user_type != 1 ? (
                                <button
                                  type="button"
                                  className="plus-btn ms-2"
                                  onClick={() => copyLinkHandler(item)}
                                  disabled={
                                    item?.topic_heading && item?.page_no
                                      ? false
                                      : true
                                  }
                                >
                                  <i class="fa-solid fa-clone"></i>
                                </button>
                              ) : null}
                            </div>
                          </li>
                        );
                      })}
                      {errors.conceptMap && (
                        <div className="text-danger fs-12">
                          {errors.conceptMap}
                        </div>
                      )}
                    </ul>
                  </div>
                ) : null}
                {/* <div className="basic-details-item mt-2 path_container">
                  <div className="pdf_left_side">
                    <label>Hyperlink Path: </label> {"topic_heading"} ={" "}
                    <input
                      disabled
                      type="text"
                      className="elibrary_pdf_page_input"
                      placeholder="page no."
                      value={4}
                      name="pdfpageno"
                      autoComplete="off"
                    />
                  </div>

                  <div className="pdf_right_side">
                    <Link to="#" onClick={() => copyHyperlinkPath("iueueue")}>
                      <i className="bi bi-bookmarks"></i>
                    </Link>
                  </div>
                </div> */}
                {state.user_type == 1 || state.user_type == 2 ? (
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>
                        No. of Citation{" "}
                        <span class="text-danger">
                          {content || state.user_type == 2 ? "*" : null}
                        </span>
                      </strong>
                    </label>
                    <input
                      className="form-control place_Holder"
                      placeholder="Enter the no. of Citation"
                      value={citation}
                      onChange={(e) => onNoOfCitationHandler(e.target.value)}
                      maxLength="5"
                    />
                    {errors.citation && (
                      <div className="text-danger fs-12">{errors.citation}</div>
                    )}
                  </div>
                ) : null}
                <div className="basic-details-item">
                  <div className="form-check">
                    <input
                      id="checkbox1"
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) => setIsCheck(e.target.checked)}
                      // Checked={true}
                    />
                    <label
                      htmlFor="checkbox1"
                      className="form-check-label custom-form-check-label"
                    >
                      I accept the{" "}
                      <a
                        href="javascript:void(0);"
                        className="link"
                        onClick={(e) => setModalTerms(true)}
                      >
                        Terms & Conditions
                      </a>
                    </label>
                  </div>
                </div>
                <div className="content-details-btn-groups mt-4">
                  <button
                    type="button"
                    onClick={(e) => onSubmit(e, 1)}
                    className="btn btn-primary details-submit-btn cursor-pointer me-2"
                    disabled={!isCheck}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={(e) => onSubmit(e, 2)}
                    className="btn btn-primary details-submit-btn cursor-pointer"
                  >
                    Save
                  </button>{" "}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal className="fade" show={modalTerms}>
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
          <Button
            onClick={() => setModalTerms(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>
            The authorized CLV LLP team member hereby covenants and agrees to
            treat as secret and confidential, to keep secure and not at any time
            for any reason to disclose or permit to be disclosed to any person
            or otherwise make use of or permit to be made use of any information
            relating to the CLV’s educational contents, technical processes,
            business affairs or finances or any such information relating to any
            member of the LLP or a supplier or customer or client of the LLP
            (Confidential Information). The team member also hereby agrees that
            any infringement or unauthorized use by them or caused by them of
            the LLP’s copyrights and Intellectual Property or Confidential
            Information, shall be held against them in any stringent legal
            recourse as may be deemed appropriate by the LLP. They also declare
            that every piece of content produced by them is entirely a product
            of their sole authorship, not plagiarized from any digital or
            non-digital sources, and true to the best of their knowledge.
          </p>
          <p>
            <strong>Note: </strong>
            Each piece of content will then be verified by Crestest admin, and
            in case of any error in terms of authenticity of the content, team
            members will instantly be notified and asked to review content under
            scrutiny. It is only after this final review that the content will
            be published.
          </p>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.elibrary.showLoading,
    contentvalidatorList: [],
    contentdesignerList: [],
    category: [],
  };
};

export default connect(mapStateToProps)(ElibraryContentRequestDetails);
