import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { validateEmail } from "../../utility/Utility";
import logo from "../../images/logo.png";
import {
  forgotPasswordData, loadingToggleAction,
} from '../../store/actions/AuthActions';

const ForgotPassword = (props) => {

  const dispatch = useDispatch();
  let errorsObj = { email: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    } else if (validateEmail(email)) {
      errorObj.email = 'Invalid email';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(forgotPasswordData(email, props.history));
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <label className="">
                          <strong>Email</strong>
                        </label>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-login btn-block"
                          disabled={props.showLoading}
                        >
                          Submit
                          {props.showLoading ?
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            : null}
                        </button>
                      </div>
                    </form>
                    <div className="new-account text-center mt-3">
                      <p className="">
                        Back to{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                    {/* <div className="new-account text-center mt-3">
                      <Link className="text-primary-custom" to="./login">
                        Sign in
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(ForgotPassword);
