import swal from "sweetalert";
import {
  getQuestionContentList,
  uploadQuestionDeveloperFile,
  submitQuestionsDetails,
  uploadSingleQuestion,
  getQuestionsDetailsById,
  developerApprovalQuestionContent,
  getQuestionsRequestDetails,
  updateQuestion,
  getQuestionsNumberById,
  removeQuestionsRequestDetailsById,
} from "../../services/AssignQuestionsServices";

import {
  GET_QUESTION_CONTENT_LIST,
  GET_QUESTION_DETAILS_BY_ID,
  GET_QUESTION_REQUEST_DETAILS_LIST,
  UPLOAD_QUESTION_BY_DEVELOPER_FILE,
} from "../constants";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../../store/actions/GlobalAction";

export function getQuestionContentListData(
  page,
  exam_category_id,
  board_id,
  exam_type_id,
  subject_id,
  class_no,
  chapter_id,
  status,
  history
) {
  return (dispatch) => {
    getQuestionContentList(
      page,
      exam_category_id,
      board_id,
      exam_type_id,
      subject_id,
      class_no,
      chapter_id,
      status
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getQuestionContentListAction(response.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function developerApprovedQuestionContentData(
  state,
  approve_status,
  reason,
  history
) {
  return (dispatch) => {
    developerApprovalQuestionContent(state.id, approve_status, reason)
      .then((response) => {
        if (response.data.status == 200) {
          history.replace({
            pathname: "/question-request-details",
            state: state,
          });
        } else if (response.data.status == 400) {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function developerRejectQuestionContentData(
  state,
  approve_status,
  reason,
  history
) {
  return (dispatch) => {
    developerApprovalQuestionContent(state.id, approve_status, reason)
      .then((response) => {
        if (response.data.status == 200) {
          history.replace({
            pathname: "/questions-list",
            state: state.filterData,
          });
        }else if (response.data.status == 400) {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function uploadQuestionDeveloperFileData(
  id,
  file,
  closeLoadingHandler,
  history
) {
  return (dispatch) => {
    uploadQuestionDeveloperFile(id, file)
      .then((response) => {
        if (response.data.status == 200) {
          closeLoadingHandler();
          dispatch(uploadQuestionDeveloperFileAction(response.data));
          utility.showSuccess(response.data.msg);
        }
      })
      .catch((error) => {
        utility.showError(error.response.data);
      });
  };
}

export function getQuestionsRequestDetailsData(id, rejected_status, history) {
  return (dispatch) => {
    getQuestionsRequestDetails(id, rejected_status)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getQuestionsRequestDetailsAction(response.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
      });
  };
}

export function submitQuestionsDetailsData(state, history) {
  return (dispatch) => {
    submitQuestionsDetails(state.id)
      .then((response) => {
        if (response.data.status == 200) {
          utility.showSuccess(response.data.msg);
          history.replace({
            pathname: "/questions-list",
            state: state.filterData,
          });
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
      });
  };
}

export function uploadSingleQuestionData(
  recid,
  question_type,
  question_no,
  css_question_no,
  question,
  option_a,
  option_b,
  option_c,
  option_d,
  answer,
  reason,
  content_status,
  history
) {
  return (dispatch) => {
    uploadSingleQuestion(
      recid,
      question_type,
      question_no,
      css_question_no,
      question,
      option_a,
      option_b,
      option_c,
      option_d,
      answer,
      reason,
      content_status
    )
      .then((response) => {
        if (response.status === 200) {
          utility.showSuccess(response.data.msg);
          history.goBack();
        } else {
          utility.showError(response.data.msg);
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
      });
  };
}

export function addAndNextSingleQuestionData(
  recid,
  question_type,
  question_no,
  css_question_no,
  question,
  option_a,
  option_b,
  option_c,
  option_d,
  answer,
  reason,
  content_status,
  onClearHandler,
  history
) {
  return (dispatch) => {
    uploadSingleQuestion(
      recid,
      question_type,
      question_no,
      css_question_no,
      question,
      option_a,
      option_b,
      option_c,
      option_d,
      answer,
      reason,
      content_status
    )
      .then((response) => {
        if (response.status === 200) {
          utility.showSuccess(response.data.msg);
          onClearHandler();
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateQuestionDetails(
  recid,
  question,
  option_a,
  option_b,
  option_c,
  option_d,
  answer,
  reason,
  content_status,
  history
) {
  return (dispatch) => {
    updateQuestion(
      recid,
      question,
      option_a,
      option_b,
      option_c,
      option_d,
      answer,
      reason,
      content_status
    )
      .then((response) => {
        if (response.status === 200) {
          utility.showSuccess(response.data.msg);
          history.goBack();
        } else {
          utility.showError(response.data.msg);
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getQuestionsDetailsByIdData(recid, history) {
  return (dispatch) => {
    getQuestionsDetailsById(recid)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getQuestionsDetailsByIdDataAction(response.data.data));
        }
      })
      .catch((error) => {
        utility.showError(error.response.data);
      });
  };
}

export function getQuestionsNumberByIdData(
  recid,
  question_type,
  setQuestionNoAndGroupId,
  history
) {
  return (dispatch) => {
    getQuestionsNumberById(recid, question_type)
      .then((response) => {
        if (response.status === 200) {
          setQuestionNoAndGroupId(response.data);
        }
      })
      .catch((error) => {
        utility.showError(error.response.data);
      });
  };
}

export function removeQuestionsRequestDetailsByIdData(
  id,
  recid,
  rejected_status,
  history
) {
  return (dispatch) => {
    removeQuestionsRequestDetailsById(id)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(
            getQuestionsRequestDetailsData(recid, rejected_status, history)
          );
          swal(response.data.msg, { icon: "success" });
        }
      })
      .catch((error) => {
        swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function getQuestionContentListAction(data) {
  return {
    type: GET_QUESTION_CONTENT_LIST,
    payload: data,
  };
}

export function uploadQuestionDeveloperFileAction(data) {
  return {
    type: UPLOAD_QUESTION_BY_DEVELOPER_FILE,
    payload: data,
  };
}

export function getQuestionsRequestDetailsAction(data) {
  return {
    type: GET_QUESTION_REQUEST_DETAILS_LIST,
    payload: data,
  };
}

export function getQuestionsDetailsByIdDataAction(data) {
  return {
    type: GET_QUESTION_DETAILS_BY_ID,
    payload: data,
  };
}
