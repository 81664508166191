
import {
    LOADING_SUBJECT,
    GET_SUBJECT_LIST,
} from '../constants';

import {
    getSubjectList,
} from '../../services/SubjectService';

import * as utility from '../../utility/Utility';

export function getSubjectListDetails(category_id, exam_type_id, board_id, history) {
    return (dispatch) => {
        getSubjectList(category_id, exam_type_id, board_id,)
            .then((response) => {
                if(response.data.status==200) {
                    dispatch(getSubjectListAction(response.data.data));
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getSubjectListAction(data) {
    return {
        type: GET_SUBJECT_LIST,
        payload: data,
    };
}

export function loadingSubject(status) {
    return {
        type: LOADING_SUBJECT,
        payload: status,
    };
}