import {
    GET_QUESTION_CONTENT_LIST,
    QUESTIOS_LOADING,
    GET_QUESTION_REQUEST_DETAILS_LIST,
    GET_QUESTION_DETAILS_BY_ID,
    UPLOAD_QUESTION_BY_DEVELOPER_FILE
} from '../constants';

const initialState = {
    contentList: [],
    totalRecord: 0,
    totalPage: 0,
    questionList: [],
    reasonsList: [],
    questionDetails:null,
    showLoading: false,
};

export function AssignQuestionsReducer(state = initialState, action) {

    if (action.type === GET_QUESTION_CONTENT_LIST) {
        return {
            ...state,
            contentList: action.payload.data,
            totalRecord: action.payload.total_record,
            totalPage: action.payload.page_count,
            showLoading: false,
        };
    }

    if (action.type === UPLOAD_QUESTION_BY_DEVELOPER_FILE) {
        return {
            ...state,
            questionList: [...state.questionList, ...action.payload.data],
            showLoading: false,
        };
    }

    if (action.type === GET_QUESTION_REQUEST_DETAILS_LIST) {
        return {
            ...state,
            questionList: action.payload.data,
            showLoading: false,
        };
    }

    if (action.type === GET_QUESTION_DETAILS_BY_ID) {
        return {
            ...state,
            questionDetails: action.payload[0],
            showLoading: false,
        };
    }

    if (action.type === QUESTIOS_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


