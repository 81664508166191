import React, {
  Fragment,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import Moment from "react-moment";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import {
  developerRejectQuestionContentData,
  developerApprovedQuestionContentData,
} from "../../../store/actions/AssignQuestionsActions";
import { rejectReasonsData } from "../../../store/actions/ElibraryActions";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const ViewQuestionDetails = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();

  let errorsObj = { reason: "", otherReason: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState("");
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    dispatch(rejectReasonsData(props.history));
  }, []);

  const onSubmitHandler = () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (reason === "" || reason === null) {
      errorObj.reason = "Reason is Required";
      error = true;
    }
    if (reason == 0 && otherReason.trim() === "") {
      errorObj.otherReason = "Reason is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      developerRejectQuestionContentData(
        state,
        reason == 0 ? otherReason : reason,
        2,
        props.history
      )
    );
  };

  const onGoBackHandler = () => {
    history.replace({
      pathname: "/questions-list",
      state: state.filterData,
    });
  };

  const onChangeRadioHandler = (event) => {
    setReason(event.target.value);
  };

  const onApprovedHanlder = () => {
    dispatch(globalLoadingAction(true));
    dispatch(developerApprovedQuestionContentData(state, "", 1, props.history));
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12"></div>
        <div className="col-xl-12">
          <div className="card content-details">
            <form>
              <div className="row">
                <div className="col-xl-2">
                  <div className="back-arrow-container">
                    <Link onClick={onGoBackHandler}>
                      <i class="fa-solid fa-arrow-left"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-4">
                  {state.exam_category && (
                    <div className="basic-details-item">
                      <label className="w-50">Category</label>
                      <span>{state.exam_category}</span>
                    </div>
                  )}
                  {state.board_name && (
                    <div className="basic-details-item">
                      <label className="w-50">Board</label>
                      <span>{state.board_name}</span>
                    </div>
                  )}
                  {state.exam_type_name && (
                    <div className="basic-details-item">
                      <label className="w-50">Type</label>
                      <span>{state.exam_type_name}</span>
                    </div>
                  )}
                  {state.class && (
                    <div className="basic-details-item">
                      <label className="w-50">Class</label>
                      <span>{state.class}</span>
                    </div>
                  )}
                  {state.subject && (
                    <div className="basic-details-item">
                      <label className="w-50">Subject</label>
                      <span>{state.subject}</span>
                    </div>
                  )}
                  {state.chapter && (
                    <div className="basic-details-item">
                      <label className="w-50">Chapter</label>
                      <span>{state.chapter}</span>
                    </div>
                  )}

                </div>
                <div className="col-xl-4">
                  {state.total_no_questions && (
                    <div className="basic-details-item">
                      <label className="w-50">No. of Questions</label>
                      <span>{state.total_no_questions}</span>
                    </div>
                  )}
                  {state.payment && (
                    <div className="basic-details-item">
                      <label className="w-50">Amount</label>
                      <span>{state.payment}</span>
                    </div>
                  )}
                  {state.time_limit && (
                    <div className="basic-details-item">
                      <label className="w-50">Time Limit</label>
                      <span>
                        <Moment format="DD/MM/YYYY">{state.time_limit}</Moment>
                      </span>
                    </div>
                  )}
                  <div className="basic-details-item">
                    <label className="w-50">Status</label>
                    <span>Pending</span>
                  </div>
                  {state.question_no_details?.length > 0 && (
                    <div className="basic-details-item display-flex">
                      <label className="w-50">Questions type</label>
                      <div className="questions-details-item">
                        {state.question_no_details.map((item, index) => {
                          return (
                            <span>
                              {item.type} - {item.number}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xl-2"></div>
                <div className="content-details-btn-groups mt-5">
                  <button
                    type="button"
                    onClick={() => setIsModal(true)}
                    className="btn btn-danger details-submit-btn me-3"
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    onClick={() => onApprovedHanlder()}
                    className="btn btn-success details-submit-btn ms-3"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal className="fade bd-example-modal-lg" show={isModal} size="md">
        <Modal.Header>
          <Modal.Title>Want to Reject ?</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <div onChange={onChangeRadioHandler} className="mb-3">
              {props.reasonsList.map((item, index) => {
                return (
                  <div className="form-check" key={item.id}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      value={item.message}
                      id={`rejectReason${item.id}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`rejectReason${item.id}`}
                    >
                      {item.message}
                    </label>
                  </div>
                );
              })}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="reason"
                  value="0"
                  id="other"
                />
                <label className="form-check-label" htmlFor="other">
                  Other
                </label>
              </div>
              {errors.reason && (
                <div className="text-danger fs-12">{errors.reason}</div>
              )}
            </div>

            {reason == 0 ? (
              <div className="form-group mb-3">
                <label className="mb-2">
                  <strong>
                    Reason<span className="text-danger">*</span>
                  </strong>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Please specify the reason to reject..."
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
                {errors.otherReason && (
                  <div className="text-danger fs-12">{errors.otherReason}</div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="preview-modal-btn-view">
            <Button variant="success" onClick={() => onSubmitHandler()}>
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    reasonsList: state.elibrary.reasonsList,
  };
};

export default connect(mapStateToProps)(ViewQuestionDetails);
