import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Moment from "react-moment";
import { Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  Tooltip,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getQuestionContentListData } from "../../../store/actions/AssignQuestionsActions";
import {
  getExamCategory,
  loadingCategotyAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getBoardDetails,
  loadingBoard,
} from "../../../store/actions/BoardActions";
import {
  getExamTypeDetails,
  loadingExamType,
} from "../../../store/actions/ExamTypeActions";
import {
  getClassData,
  getClassAction,
  loadingClass,
} from "../../../store/actions/ClassAction";
import {
  getSubjectListDetails,
  getSubjectListAction,
  loadingSubject,
} from "../../../store/actions/SubjectActions";
import {
  getChaptersData,
  getChapterAction,
  loadingChapter,
} from "../../../store/actions/ChapterAction";
import statusText from "../statusList";

const QuestionsList = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [active, setActive] = useState(state?.active ? state.active : 1);
  const [category, setCategory] = useState(
    state?.category ? state.category : ""
  );
  const [board, setBoard] = useState(state?.board ? state.board : "");
  const [type, setType] = useState(state?.type ? state.type : "");
  const [subject, setSubject] = useState(state?.subject ? state.subject : "");
  const [classRoom, setClassRoom] = useState(
    state?.classRoom ? state.classRoom : ""
  );
  const [chapter, setChapter] = useState(state?.chapter ? state.chapter : "");
  const [status, setstatus] = useState(state?.status ? state.status : "");

  useEffect(() => {
    dispatch(loadingCategotyAction(true));
    dispatch(getExamCategory(props.history));
    dispatch(getBoardDetails(props.history));
    if (category) {
      dispatch(getExamTypeDetails(category, props.history));
      dispatch(getClassData(category, props.history));
    }
    if ((category && type) || (category && board)) {
      dispatch(
        getSubjectListDetails(
          category,
          category == 2 ? type : 0,
          category == 1 ? board : 0,
          props.history
        )
      );
    }
    if (subject && classRoom) {
      dispatch(getChaptersData(subject, classRoom, props.history));
    }
  }, []);

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getQuestionContentListData(
        active,
        category,
        board,
        type,
        subject,
        classRoom,
        chapter,
        status,
        props.history
      )
    );
  }, [category, board, type, subject, classRoom, chapter, status, active]);

  const onResetFilterClick = () => {
    setActive(1);
    setCategory("");
    setType("");
    setClassRoom("");
    setBoard("");
    setSubject("");
    setChapter("");
    setstatus("");
    dispatch(globalLoadingAction(true));
    dispatch(
      getQuestionContentListData(1, "", "", "", "", "", "", "", props.history)
    );
  };

  const getCategotyHandler = () => {
    if (props.categoryList.length == 0) {
      dispatch(loadingCategotyAction(true));
      dispatch(getExamCategory(props.history));
    }
  };

  const onChangeCategoryHandler = (val) => {
    setActive(1);
    setCategory(val);
    setType("");
    setBoard("");
    setClassRoom("");
    setSubject("");
    setChapter("");
    setstatus("");
  };

  const getBoardHandler = () => {
    if (props.boardList.length == 0) {
      dispatch(loadingBoard(true));
      dispatch(getBoardDetails(props.history));
    }
  };

  const onChangeBoardHandler = (val) => {
    setActive(1);
    setBoard(val);
    setType("");
    setSubject("");
    dispatch(getSubjectListAction([]));
  };

  const getTypeHandler = () => {
    if (props.typeList.length == 0) {
      dispatch(loadingExamType(true));
      dispatch(getExamTypeDetails(category, props.history));
    }
  };

  const onChangeTypeHandler = (val) => {
    setActive(1);
    setType(val);
    setBoard("");
    setSubject("");
    setClassRoom("");
    setChapter("");
    dispatch(getSubjectListAction([]));
    dispatch(getClassAction([]));
  };

  const getClassHandler = () => {
    if (props.classList.length == 0) {
      dispatch(loadingClass(true));
      dispatch(getClassData(category, props.history));
    }
  };

  const onChangeClassHandler = (val) => {
    setActive(1);
    setClassRoom(val);
    setChapter("");
    dispatch(getChapterAction([]));
  };

  const getSubjectHandler = () => {
    if (props.subjectList.length == 0) {
      dispatch(loadingSubject(true));
      dispatch(
        getSubjectListDetails(
          category,
          category == 2 ? type : 0,
          category == 1 ? board : 0,
          props.history
        )
      );
    }
  };

  const onChangeSubjectHandler = (val) => {
    setActive(1);
    setSubject(val);
    setChapter("");
    dispatch(getChapterAction([]));
  };

  const getChapterHandler = () => {
    if (props.chapterList.length == 0) {
      dispatch(loadingChapter(true));
      dispatch(getChaptersData(subject, classRoom, props.history));
    }
  };

  const onChangeChapterHandler = (val) => {
    setActive(1);
    setChapter(val);
  };

  const onChangeStatusHandler = (val) => {
    setActive(1);
    setstatus(val);
  };

  const handlePageClick = (data) => {
    setActive(data.selected + 1);
    // dispatch(globalLoadingAction(true));
    // dispatch(
    //   getQuestionContentListData(
    //     data.selected + 1,
    //     category,
    //     board,
    //     type,
    //     subject,
    //     classRoom,
    //     chapter,
    //     status,
    //     props.history
    //   )
    // );
  };

  return (
    <Fragment>
      <Row>
        {/* {console.log("props.contentList......", props.contentList)} */}
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="contentlist-filter-view">
                <div className="reset-btn-view">
                  <button
                    type="button"
                    className="btn btn-primary filter-button-reset"
                    onClick={(e) => onResetFilterClick()}
                    disabled={category || status ? false : true}
                  >
                    Reset
                  </button>
                </div>
                <div className="row">
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        {props.loadingCategory ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <i className="bi bi-caret-down-fill"></i>
                        )}
                      </span>
                      <select
                        className="form-control filter-form-control"
                        value={category}
                        //onClick={getCategotyHandler}
                        onChange={(e) =>
                          onChangeCategoryHandler(e.target.value)
                        }
                      >
                        <option value="">Select Category</option>
                        {props.categoryList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.category}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {category == 1 ? (
                    <div className="col-sm-2 px-1">
                      <div className="basic-dropdown">
                        <span className="drop_down_arrow add-Question-status-arrow-position">
                          {props.loadingBoard ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <i className="bi bi-caret-down-fill"></i>
                          )}
                        </span>
                        <select
                          // defaultValue={selectDefaultValue}
                          className="form-control filter-form-control"
                          value={board}
                          //onClick={getBoardHandler}
                          onChange={(e) => onChangeBoardHandler(e.target.value)}
                        >
                          <option value="">Select Board</option>
                          {props.boardList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : category == "" ? (
                    <div className="col-sm-2 px-1">
                      <div className="basic-dropdown">
                        <span className="drop_down_arrow add-Question-status-arrow-position">
                          <i className="bi bi-caret-down-fill"></i>
                        </span>
                        <select
                          className="form-control filter-form-control"
                          disabled
                        >
                          <option value="">Select Board/Type</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="col-sm-2 px-1">
                      <div className="basic-dropdown">
                        <span className="drop_down_arrow add-Question-status-arrow-position">
                          {props.loadingType ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <i className="bi bi-caret-down-fill"></i>
                          )}
                        </span>
                        <select
                          className="form-control filter-form-control"
                          value={type}
                          onClick={getTypeHandler}
                          onChange={(e) => onChangeTypeHandler(e.target.value)}
                        >
                          <option value="">Select type</option>
                          {props.typeList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.type_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        {props.loadingClass ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <i className="bi bi-caret-down-fill"></i>
                        )}
                      </span>
                      <select
                        className="form-control filter-form-control"
                        value={classRoom}
                        disabled={parseInt(type) == 2 || board ? false : true}
                        onClick={getClassHandler}
                        onChange={(e) => onChangeClassHandler(e.target.value)}
                      >
                        <option value="">Select class</option>
                        {props.classList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.short_code}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        {props.loadingSubject ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <i className="bi bi-caret-down-fill"></i>
                        )}
                      </span>
                      <select
                        // defaultValue={selectDefaultValue}
                        className="form-control filter-form-control"
                        value={subject}
                        disabled={board || type ? false : true}
                        onClick={getSubjectHandler}
                        onChange={(e) => onChangeSubjectHandler(e.target.value)}
                      >
                        <option value="">Select subject</option>
                        {props.subjectList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        {props.loadingChapter ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <i className="bi bi-caret-down-fill"></i>
                        )}
                      </span>
                      <select
                        // defaultValue={selectDefaultValue}
                        className="form-control filter-form-control"
                        value={chapter}
                        disabled={subject && classRoom ? false : true}
                        onChange={(e) => onChangeChapterHandler(e.target.value)}
                        onClick={getChapterHandler}
                      >
                        <option value="">Select chapter</option>
                        {props.chapterList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.chapter_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        className="form-control filter-form-control"
                        value={status}
                        onChange={(e) => onChangeStatusHandler(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Accept</option>
                        <option value="2">Reject</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {props.contentList.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="col_heading">
                        <strong>Category</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Exam Type</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Class</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Board</strong>
                      </th>
                      <th className="chapter_col col_heading">
                        <strong>Subject</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Chapter</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Request Date</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Timelimit</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Upload Date</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Admin Status</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Creator Status</strong>
                      </th>
                      <th className="col_heading">
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.contentList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.exam_category}</td>
                          <td>
                            {item.exam_type_name ? (
                              item.exam_type_name
                            ) : (
                              <span className="text-center">-</span>
                            )}
                          </td>
                          <td>
                            {item.class ? (
                              item.class
                            ) : (
                              <span className="text-center">-</span>
                            )}
                          </td>
                          <td>
                            {item.board_name ? (
                              item.board_name
                            ) : (
                              <span className="text-center">-</span>
                            )}
                          </td>
                          <td className="chapter_col">{item.subject}</td>
                          <td>{item.chapter}</td>
                          <td>
                            <span>{item.request_date}</span>
                          </td>
                          <td>
                            <span>
                              <Moment format="DD/MM/YYYY">
                                {item.time_limit}
                              </Moment>
                            </span>
                          </td>
                          <td>
                            {item.uploaded_date ? (
                              <span>
                                <Moment format="DD/MM/YYYY">
                                  {item.uploaded_date}
                                </Moment>
                              </span>
                            ) : (
                              <span className="text-center d-block">-</span>
                            )}
                          </td>
                          <td>
                            {(() => {
                              switch (parseInt(item.admin_approval_status)) {
                                case 0:
                                  return (
                                    <div className="btn-xs btn btn-outline-warning lbl-assigned">
                                      <span>Pending</span>
                                    </div>
                                  );
                                case 1:
                                  return (
                                    <div className="btn-xs btn btn-outline-success lbl-assigned">
                                      <span>Approved</span>
                                    </div>
                                  );
                                case 2:
                                  return (
                                    <div className="btn-xs btn btn-outline-danger lbl-assigned">
                                      <span>Rejected</span>
                                    </div>
                                  );
                                case 3:
                                  return (
                                    <span className="text-center d-block">
                                      -
                                    </span>
                                  );
                                case 4:
                                  return (
                                    <div className="btn-xs btn btn-outline-danger lbl-assigned">
                                      <span>Canceled</span>
                                    </div>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </td>
                          <td>
                            <div>
                              {(() => {
                                switch (parseInt(item.approved_status)) {
                                  case 0:
                                    return (
                                      <div className="btn-xs btn btn-warning lbl-assigned">
                                        <span>Pending</span>
                                      </div>
                                    );
                                  case 1:
                                    return (
                                      <div className="btn-xs btn btn-success lbl-assigned">
                                        <span>Accepted</span>
                                      </div>
                                    );
                                  case 2:
                                    return (
                                      <div className="btn-xs btn btn-danger lbl-assigned">
                                        <span>Rejected</span>
                                      </div>
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              {item.approved_status == 2 ||
                              item.status == 1 ||
                              item.admin_approval_status == 4 ? (
                                <button
                                  disabled
                                  type="button"
                                  className="btn btn-info btn-xs"
                                >
                                  View
                                </button>
                              ) : item.approved_status == 1 ? (
                                <Link
                                  className="btn btn-info btn-xs"
                                  to={{
                                    pathname: "/question-request-details",
                                    state: {
                                      ...item,
                                      filterData: {
                                        active: active,
                                        category: category,
                                        board: board,
                                        type: type,
                                        subject: subject,
                                        classRoom: classRoom,
                                        chapter: chapter,
                                        status: status,
                                      },
                                    },
                                  }}
                                >
                                  View
                                </Link>
                              ) : item.approved_status == 0 ? (
                                <Link
                                  className="btn btn-info btn-xs"
                                  to={{
                                    pathname: "/view-question-details",
                                    state: {
                                      ...item,
                                      filterData: {
                                        active: active,
                                        category: category,
                                        board: board,
                                        type: type,
                                        subject: subject,
                                        classRoom: classRoom,
                                        chapter: chapter,
                                        status: status,
                                      },
                                    },
                                  }}
                                >
                                  View
                                </Link>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="no_data mt-5">
                  <strong>No data found</strong>
                </div>
              )}
              <div className="pagination-container">
                {parseInt(props.totalRecord) > 0 ? (
                  <div className="page-result">
                    <p>
                      Showing{" "}
                      {(active - 1) * 10 +
                        1 +
                        " - " +
                        ((active - 1) * 10 + props.contentList.length) +
                        " of " +
                        "( " +
                        props.totalRecord +
                        " items )"}
                    </p>
                  </div>
                ) : null}

                {parseInt(props.totalRecord) > 10 ? (
                  <ReactPaginate
                    containerClassName="pagination-gutter false pagination-circle pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLabel="..."
                    nextLabel={<i className="la la-angle-right"></i>}
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousLabel={<i className="la la-angle-left"></i>}
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={props.totalPage}
                    //initialPage={active - 1}
                    forcePage={active - 1}
                  />
                ) : null}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.elibrary.showLoading,
    list: state.assignQuestions.list,
    contentList: state.assignQuestions.contentList,
    totalRecord: state.assignQuestions.totalRecord,
    totalPage: state.assignQuestions.totalPage,
    categoryList: state.category.categoryList,
    loadingCategory: state.category.showLoading,
    statusText: statusText,
    boardList: state.board.boardList,
    loadingBoard: state.board.showLoading,
    classList: state.class.classList,
    loadingClass: state.class.showLoading,
    typeList: state.examtype.examTypeList,
    subjectList: state.subject.subjectList,
    loadingSubject: state.subject.showLoading,
    loadingType: state.examtype.showLoading,
    chapterList: state.chapter.chapterList,
    loadingChapter: state.chapter.showLoading,
    reasonsList: state.elibrary.reasonsList,
  };
};

export default connect(mapStateToProps)(QuestionsList);
