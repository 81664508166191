import {
    ACCOUNT_VERIFI
} from '../constants';

const initialState = {
    status: false,
    showLoading: false,
};

export function CheckVerifiReducer(state = initialState, action) {

    if (action.type === ACCOUNT_VERIFI) {
        return {
            ...state,
            status: action.payload == 200 ? true : false,
            showLoading: false,
        };
    }

    // if (action.type === QUESTIONS_CONTENT_LOADING) {
    //     return {
    //         ...state,
    //         showLoading: action.actions.payload,
    //     };
    // }

    return state;
}


