import {
    LOADING_CHAPTER,
    GET_CHAPTER_LIST
} from '../constants';

const initialState = {
    chapterList: [],
    showLoading: false,
};

export function ChapterReducer(state = initialState, action) {

    if (action.type === GET_CHAPTER_LIST) {
        return {
            ...state,
            chapterList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === LOADING_CHAPTER) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


