
import {
    LOADING_EXAMTYPE,
    GET_EXAMTYPE_LIST,
} from '../constants';

import {
    getExamType,
} from '../../services/ExamTypeService';

import * as utility from '../../utility/Utility';

export function getExamTypeDetails(exam_category, history) {
    return (dispatch) => {
        getExamType(exam_category)
            .then((response) => {
                dispatch(getExamTypeList(response.data.data));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getExamTypeList(data) {
    return {
        type: GET_EXAMTYPE_LIST,
        payload: data,
    };
}

export function loadingExamType(status) {
    return {
        type: LOADING_EXAMTYPE,
        payload: status,
    };
}