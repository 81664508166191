import swal from "sweetalert";
import {
    getChapters,
} from '../../services/ChapterServices';

import {
    LOADING_CHAPTER,
    GET_CHAPTER_LIST
} from '../constants';

import * as utility from '../../utility/Utility';

export function getChaptersData(branch_id,classRoom, history) {
    return (dispatch) => {
        getChapters(branch_id,classRoom)
            .then((response) => {
                if(response.data.status === 200){
                    dispatch(getChapterAction(response.data.data));
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}


export function getChapterAction(data) {
    return {
        type: GET_CHAPTER_LIST,
        payload: data,
    };
}

export function loadingChapter(status) {
    return {
        type: LOADING_CHAPTER,
        payload: status,
    };
}