import {
    LOADING_CLASS,
    GET_CLASS_LIST
} from '../constants';

const initialState = {
    classList: [],
    showLoading: false,
};

export function ClassReducer(state = initialState, action) {

    if (action.type === GET_CLASS_LIST) {
        return {
            ...state,
            classList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === LOADING_CLASS) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


