import {
    getQuestionType,
} from '../../services/QuestionTypeServices';

import {
    LOADING_QUESTION_TYPE,
    GET_QUESTION_TYPE_LIST,
} from '../constants';

import * as utility from '../../utility/Utility';


export function getQuestionTypeData(history) {
    return (dispatch) => {
        getQuestionType()
            .then((response) => {
                if(response.data.status === 200) {
                    dispatch(getQuestionTypeAction(response.data.data));
                }
                dispatch(loadingQuestionType(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingQuestionType(false));
                utility.showError(error.response.data);
            });
    };
}

export function getQuestionTypeAction(data) {
    return {
        type: GET_QUESTION_TYPE_LIST,
        payload: data,
    };
}

export function loadingQuestionType(status) {
    return {
        type: LOADING_QUESTION_TYPE,
        payload: status,
    };
}