import swal from "sweetalert";
import { getErningList,paymentTransactionDetails } from "../../services/EarningServices";

import { GET_EARNING_LIST } from "../constants";
import { globalLoadingAction } from "../../store/actions/GlobalAction";
import * as utility from "../../utility/Utility";

export function getErningListData(content_type,payment_status,category_id,board,exam_type,class_no,subject,chapter,transaction_id,page,history) {
  return (dispatch) => {
    getErningList(content_type,payment_status,category_id,board,exam_type,class_no,subject,chapter,transaction_id,page)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(getErningListAction(response.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function paymentTransactionDetailsList(id,getTransactionDetailsCallBack,history) {
  return (dispatch) => {
    paymentTransactionDetails(id)
      .then((response) => {
        if (response.data.status === 200) {
          getTransactionDetailsCallBack(response.data);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function getErningListAction(data) {
  return {
    type: GET_EARNING_LIST,
    payload: data,
  };
}
