import { combineReducers } from "redux";
import { AuthReducer } from "./AuthReducer";
import { QuestionsReducer } from "./QuestionsReducer";
import { CheckVerifiReducer } from "./CheckVerifiReducer";
import { ExamCategoryReducer } from "./ExamCategoryReducer";
import { BranchReducer } from "./BranchReducer";
import { ChapterReducer } from "./ChapterReducer";
import { ClassReducer } from "./ClassReducer";
import { ElibraryReducer } from "./ElibraryReducer";
import { BoardReducer } from "./BoardReducer";
import { ExamTypeReducer } from "./ExamTypeReducer";
import { SubjectReducer } from "./SubjectReducer";
import { GlobalReducer } from "./GlobalReducer";
import { AssignQuestionsReducer } from "./AssignQuestionsReducer";
import { QuestionTypeReducer } from "./QuestionTypeReducer";
import { EarningReducer } from "./EarningReducer";
import PostsReducer from "./PostsReducer";
import todoReducers from "./Reducers";

const rootReducers = combineReducers({
  global: GlobalReducer,
  auth: AuthReducer,
  posts: PostsReducer,
  questions: QuestionsReducer,
  checkverifi: CheckVerifiReducer,
  category: ExamCategoryReducer,
  branch: BranchReducer,
  chapter: ChapterReducer,
  class: ClassReducer,
  elibrary: ElibraryReducer,
  board: BoardReducer,
  examtype: ExamTypeReducer,
  subject: SubjectReducer,
  assignQuestions: AssignQuestionsReducer,
  questionType:QuestionTypeReducer,
  earning:EarningReducer,
  todoReducers,
});

export default rootReducers;
