import React, {
  Fragment,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import swal from "sweetalert";
import { Row, Col, Card, Table, Button, Modal, Spinner } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import Moment from "react-moment";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import {
  uploadQuestionDeveloperFileData,
  getQuestionsRequestDetailsData,
  submitQuestionsDetailsData,
  removeQuestionsRequestDetailsByIdData,
} from "../../../store/actions/AssignQuestionsActions";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const QuestionRequestDetails = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const { state } = useLocation();

  let errorsObj = { file: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [file, setFile] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [isCheking, setIsCheking] = useState(false);
  const [modalTerms, setModalTerms] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getQuestionsRequestDetailsData(
        state.id,
        state.admin_approval_status == 0 || state.admin_approval_status == 3
          ? 0
          : 1,
        props.history
      )
    );
  }, []);

  const getFileNme = (file_path) => {
    let myArray = file_path?.split("/");
    return myArray[myArray.length - 1];
  };

  const onGoBackHandler = () => {
    history.replace({
      pathname: "/questions-list",
      state: state.filterData,
    });
  };

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (file === "" || file === null) {
      errorObj.file = "File is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setUploadLoading(true);
    dispatch(
      uploadQuestionDeveloperFileData(
        state.id,
        file,
        closeLoadingHandler,
        props.history
      )
    );
  }

  const onFinalSubmit = () => {
    dispatch(globalLoadingAction(true));
    dispatch(submitQuestionsDetailsData(state, props.history));
  };

  const onSubmitHandler = () => {
    swal({
      title: "Are you sure?",
      text: "You want to submit!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onFinalSubmit();
      }
    });
  };

  const closeLoadingHandler = () => {
    setUploadLoading(false);
    setIsModal(false);
    setFile(null);
    fileRef.current.value = "";
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const disabledBtn = () => {
    let status = false;
    if (props.developerType == 1 && state.admin_approval_status != 2) {
      if (props.questionList.length >= state.total_no_questions) {
        props.questionList.forEach((element) => {
          if (element.status == 0 || element.status == 2) {
            status = true;
            return;
          }
        });
      } else {
        status = true;
      }
    } else {
      props.questionList.forEach((element) => {
        if (element.status == 0 || element.status == 2) {
          status = true;
          return;
        }
      });
    }
    return status;
  };

  const onRemoveHandler = (item) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          removeQuestionsRequestDetailsByIdData(
            item.id,
            state.id,
            state.admin_approval_status == 0 || state.admin_approval_status == 3
              ? 0
              : 1,
            props.history
          )
        );
      }
    });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card content-details">
            <div className="row">
              <div className="col-xl-2">
                <div className="back-arrow-container">
                  <Link onClick={onGoBackHandler}>
                    <i class="fa-solid fa-arrow-left"></i>
                  </Link>
                </div>
              </div>
              <div className="col-xl-4">
                {state.exam_category && (
                  <div className="basic-details-item">
                    <label className="w-50">Category</label>
                    <span>{state.exam_category}</span>
                  </div>
                )}
                {state.board_name && (
                  <div className="basic-details-item">
                    <label className="w-50">Board</label>
                    <span>{state.board_name}</span>
                  </div>
                )}
                {state.exam_type_name && (
                  <div className="basic-details-item">
                    <label className="w-50">Type</label>
                    <span>{state.exam_type_name}</span>
                  </div>
                )}

                {state.class && (
                  <div className="basic-details-item">
                    <label className="w-50">Class</label>
                    <span>{state.class}</span>
                  </div>
                )}
                {state.subject && (
                  <div className="basic-details-item">
                    <label className="w-50">Subject</label>
                    <span>{state.subject}</span>
                  </div>
                )}
                {state.chapter && (
                  <div className="basic-details-item">
                    <label className="w-50">Chapter</label>
                    <span>{state.chapter}</span>
                  </div>
                )}
              </div>
              <div className="col-xl-4">
                {state.total_no_questions && (
                  <div className="basic-details-item">
                    <label className="w-50">No. of Questions</label>
                    <span>{state.total_no_questions}</span>
                  </div>
                )}
                {state.payment && (
                  <div className="basic-details-item">
                    <label className="w-50">Amount</label>
                    <span>{state.payment}</span>
                  </div>
                )}
                {state.time_limit && (
                  <div className="basic-details-item">
                    <label className="w-50">Time Limit</label>
                    <span>
                      <Moment format="DD/MM/YYYY">{state.time_limit}</Moment>
                    </span>
                  </div>
                )}

                <div className="basic-details-item">
                  <label className="w-50">Status</label>
                  <span>Accepted</span>
                </div>

                {state.question_no_details?.length > 0 && (
                  <div className="basic-details-item display-flex">
                    <label className="w-50">Questions type</label>
                    <div className="questions-details-item">
                      {state.question_no_details.map((item, index) => {
                        return (
                          <span>
                            {item.type} - {item.number}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-2"></div>
              {props.developerType !== 1 ? (
                <>
                  <div className="col-xl-2"></div>
                  <div className="col-xl-10">
                    <div className="basic-details-item basic-files-details">
                      <div>
                        <label>Uploaded Files by developer :</label>
                      </div>
                      <div className="basic-files-details-item">
                        {state.content_file_path &&
                        state.content_file_path.length > 0 ? (
                          state.content_file_path.map((item, index) => {
                            return (
                              <a href={item}>
                                {getFileNme(item)}{" "}
                                <i
                                  class="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            );
                          })
                        ) : (
                          <span>No data found</span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {props.developerType == 1 ? (
                <div className="content-details-btn-groups mt-2">
                  <button
                    type="button"
                    onClick={() => setIsModal(true)}
                    className="btn btn-success me-3"
                    disabled={state.admin_approval_status == 2 ? true : false}
                  >
                    Bulk Upload
                  </button>
                  {state.admin_approval_status == 2 ? (
                    <button
                      type="button"
                      className="btn btn-success me-3"
                      disabled
                    >
                      Add Individual
                    </button>
                  ) : (
                    <Link
                      className="btn btn-success ms-3"
                      to={{
                        pathname: "/upload-questions",
                        state: state,
                      }}
                    >
                      Add Individual
                    </Link>
                  )}
                </div>
              ) : null}
            </div>
            <div className="row mt-5">
              <div className="table-scrollable-view">
                {props.questionList.length > 0 ? (
                  <>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>#</strong>
                          </th>
                          <th>
                            <strong>Question Type</strong>
                          </th>
                          <th>
                            <strong>Question No.</strong>
                          </th>
                          <th>
                            <strong>Group Id</strong>
                          </th>
                          <th>
                            <strong>Status</strong>
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <strong>Action</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.questionList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.question_type}</td>
                              <td>{item.question_no}</td>
                              <td>{item.css_group_id}</td>
                              <td>
                                {(() => {
                                  switch (parseInt(item.status)) {
                                    case 0:
                                      return (
                                        <span className="badge bg-warning lbl-assigned">
                                          Pending
                                        </span>
                                      );
                                    case 1:
                                      return (
                                        <span className="badge bg-success lbl-assigned">
                                          Added
                                        </span>
                                      );
                                    case 2:
                                      return (
                                        <span className="badge bg-danger lbl-assigned">
                                          Drafted
                                        </span>
                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </td>
                              <td>
                                <div className="qws-request-btn-group">
                                  <Link
                                    className="btn-primary light btn-edit"
                                    to={{
                                      pathname: "/update-questions",
                                      state: item,
                                    }}
                                  >
                                    <i className="fa fa-edit m-0"></i>
                                  </Link>
                                  {props.developerType == 1 ? (
                                    <button
                                      onClick={() => onRemoveHandler(item)}
                                      className="btn-primary light btn-edit btn-delete-dam"
                                      disabled={
                                        state.admin_approval_status == 2
                                          ? true
                                          : false
                                      }
                                    >
                                      <i className="fa fa-trash m-0"></i>
                                    </button>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div className="basic-details-item">
                      <div className="form-check">
                        <input
                          disabled={disabledBtn()}
                          id="checkbox1"
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => setIsCheking(e.target.checked)}
                          //Checked={true}
                        />
                        <label
                          htmlFor="checkbox1"
                          className="form-check-label custom-form-check-label"
                        >
                          I accept the{" "}
                          {disabledBtn() ? (
                            <a href="javascript:void(0);" className="link">
                              Terms & Conditions
                            </a>
                          ) : (
                            <a
                              href="javascript:void(0);"
                              className="link"
                              onClick={(e) => setModalTerms(true)}
                            >
                              Terms & Conditions
                            </a>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="content-details-btn-groups mt-4">
                      <button
                        type="button"
                        onClick={() => onSubmitHandler()}
                        className="btn btn-primary details-submit-btn me-2"
                        disabled={!isCheking}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="no_data">
                    <strong>No data found</strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade bd-example-modal-lg" show={isModal} size="lg">
        <form onSubmit={onSubmit}>
          <Modal.Header>
            <Modal.Title>Bulk Upload</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setIsModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div className="basic-form">
              <div className="mb-3">
                <div className="bulk-qus-modal-view">
                  <div>
                    <label
                      htmlFor="formFileDisabled"
                      className="form-label file-form-label"
                    >
                      Select File<span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="bulk-qus-modal-excel-view">
                    <div className="bulk-tem-btn-view me-3">
                      <span>
                        <i class="fa-regular fa-file-excel"></i>
                      </span>
                      {uploadLoading ? (
                        <a>Download CSS Template</a>
                      ) : (
                        <a href={GlobalConfigs.SAMPLE_CSS}>
                          Download CSS Template
                        </a>
                      )}
                    </div>
                    <div className="bulk-tem-btn-view">
                      <span>
                        <i class="fa-regular fa-file-excel"></i>
                      </span>
                      {uploadLoading ? (
                        <a>Download Template</a>
                      ) : (
                        <a href={GlobalConfigs.EXCEL_SAMPLE_QUESTIONS}>
                          Download Template
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                <input
                  ref={fileRef}
                  className="form-control"
                  type="file"
                  id="formFileDisabled"
                  accept=".xlsx,.xls,.csv"
                  onChange={handleChange}
                  disabled={uploadLoading}
                />
                {errors.file && (
                  <div className="text-danger fs-12 mt-1">{errors.file}</div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="preview-modal-btn-view">
              <Button
                disabled={uploadLoading}
                type="submit"
                variant="success"
                className="submit-modal-btn"
              >
                Submit
                {uploadLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal className="fade" show={modalTerms}>
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
          <Button
            onClick={() => setModalTerms(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <h5>Declaration of confidentiality and ownership of content</h5>
          <p>
            {/* <a href="#" className="link">
              adipiscing
            </a>{" "} */}
            The authorized CLV LLP team member hereby covenants and agrees to
            treat as secret and confidential, to keep secure and not at any time
            for any reason to disclose or permit to be disclosed to any person
            or otherwise make use of or permit to be made use of any information
            relating to the CLV’s educational contents, technical processes,
            business affairs or finances or any such information relating to any
            member of the LLP or a supplier or customer or client of the LLP
            (Confidential Information). The team member also hereby agrees that
            any infringement or unauthorized use by them or caused by them of
            the LLP’s copyrights and Intellectual Property or Confidential
            Information, shall be held against them in any stringent legal
            recourse as may be deemed appropriate by the LLP. They also declare
            that every piece of content produced by them is entirely a product
            of their sole authorship, not plagiarized from any digital or
            non-digital sources, and true to the best of their knowledge.
          </p>
          <p>
            <strong>Note: </strong>
            Each piece of content will then be verified by Crestest admin, and
            in case of any error in terms of authenticity of the content, team
            members will instantly be notified and asked to review content under
            scrutiny. It is only after this final review that the content will
            be published.
          </p>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    questionList: state.assignQuestions.questionList,
    developerType: state.auth.developerType,
  };
};

export default connect(mapStateToProps)(QuestionRequestDetails);
