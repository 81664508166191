import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function getQuestionContentList(page, exam_category_id, board_id, exam_type_id, subject_id, class_no, chapter_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        exam_category_id,
        board_id,
        exam_type_id,
        subject_id,
        class_no,
        chapter_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_CONTENT_DEVELOPER_QUESTIONS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function uploadQuestionDeveloperFile(recid, upload_excel) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const formData = new FormData();
    formData.append("recid", recid);
    formData.append("upload_excel", upload_excel);
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPLOAD_CONTENT_DEVELOPER_QUESTIONS_API,
        method: "POST",
        data: formData,
        headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`
        },
    });
}

export function getQuestionsRequestDetails(recid,rejected_status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        recid,
        rejected_status,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_DEVELOPER_QUESTIONS_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function submitQuestionsDetails(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        recid,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_DEVELOPER_QUESTIONS_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function uploadDesignerConceptmap(id, file) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const formData = new FormData();
    formData.append("concept_pdf_file", file);
    formData.append("recid", id);

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPLOAD_CONTENT_DESIGNER_CONCEPT_MAP_ELIBRARY_API,
        method: "POST",
        data: formData,
        headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`
        },
    });
}

export function developerApprovalQuestionContent(recid, reason, approve_status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        approve_status,
        reason
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_DEVELOPER_CONTENT_APPROVED_AND_REJECT_QUESTIONS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function uploadSingleQuestion(recid, question_type,question_no,css_question_no,question,option_a,option_b,option_c,option_d,answer,reason,content_status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        question_type,
        question_no,
        css_question_no,
        question,
        option_a,
        option_b,
        option_c,
        option_d,
        answer,
        reason,
        content_status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DEVELOPER_ADD_QUESTIONS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateQuestion(recid,question,option_a,option_b,option_c,option_d,answer,reason,content_status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        question,
        option_a,
        option_b,
        option_c,
        option_d,
        answer,
        reason,
        content_status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DEVELOPER_UPDATE_QUESTIONS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getQuestionsDetailsById(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    
    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_QUESTION_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getQuestionsNumberById(recid,question_type) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    
    const postData = {
        recid,
        question_type
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GENERATE_QUESTION_NUMBER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeQuestionsRequestDetailsById(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        recid
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_DEVELOPER_QUESTIONS_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}