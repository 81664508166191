import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import {
	loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';

import logo from "../../images/logo.png";
import { validateEmail } from "../../utility/Utility";

function Login(props) {

	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [email, setEmail] = useState('developer@yopmail.com'); //developer@yopmail.com
	const [password, setPassword] = useState('12345678'); //12345678
	const dispatch = useDispatch();

	function onLogin(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		} else if (validateEmail(email)) {
			errorObj.email = 'Invalid email';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(loadingToggleAction(true));
		dispatch(loginAction(email, password, props.history));
	}

	return (
		<>
			<div className="authincation h-100 p-meddle">
				<div className="container h-100">
					<div className="row justify-content-center h-100 align-items-center">
						<div className="col-md-6">
							<div className="authincation-content">
								<div className="row no-gutters">
									<div className="col-xl-12">
										<div className="auth-form">
											<div className="text-center mb-3">
												<Link to="/login">
													<img src={logo} alt="" />
												</Link>
											</div>
											<h4 className="text-center mb-4 ">Sign in your account</h4>
											<form onSubmit={onLogin}>
												<div className="form-group">
													<label className="mb-1 ">
														<strong>Email</strong>
													</label>
													<input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
												</div>
												{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
												<div className="form-group mt-3">
													<label className="mb-1 ">
														<strong>Password</strong>
													</label>
													<input
														value={password}
														type="password"
														onChange={(e) =>
															setPassword(e.target.value)
														}
														className="form-control"
														placeholder="Password"
													/>
												</div>
												{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
												<div className="text-center mt-4">
													<button type="submit" className="btn btn-block btn-login" disabled={props.showLoading}>
														Sign me in
														{props.showLoading ?
															<Spinner
																as="span"
																animation="border"
																size="sm"
																role="status"
																aria-hidden="true"
															/>
															: null}
													</button>
												</div>
											</form>

											<div className="new-account text-center mt-3">
												<Link className="text-primary-custom" to="./page-forgot-password">
													Forgot Password
												</Link>

												{/* <Link className="text-primary-custom" to="./reset-password">
													Reset Password
												</Link> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);