import { toast } from 'react-toastify'

export function showSuccess(message) {
    toast.success(message);
}
export function showError(message) {
    toast.error(message);
}
export function showWarning(message) {
    toast.warning(message);
}

export function validateName(name) {

    let pattern = /^[a-zA-Z ]*$/.test(name);
    return pattern;
}

export function validateEmail(email) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) === false) {
        return true;
    }
}
export function validatePhone(phone) {
    var validatePhone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(phone);
    return validatePhone;
}
export function validatePassword(password) {
    //eslint-disable-next-line
    var anUpperCase = /[A-Z]/;
    //eslint-disable-next-line
    var aLowerCase = /[a-z]/;
    //eslint-disable-next-line
    var aNumber = /[0-9]/;
    //eslint-disable-next-line
    var aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
    var obj = {};
    obj.result = true;

    if (password.length < 6) {
        obj.result = false;
        obj.error = "Password Strength must be minimum 6 characters!"
        return obj;
    }

    var numUpper = 0;
    var numNums = 0;
    var numSpecials = 0;
    for (var i = 0; i < password.length; i++) {
        if (anUpperCase.test(password[i]))
            numUpper++;
        else if (aNumber.test(password[i]))
            numNums++;
        else if (aSpecial.test(password[i]))
            numSpecials++;
    }

    if (numUpper < 1 || numNums < 1 || numSpecials < 1) {
        obj.result = false;
        obj.error = "Strong Password Recomended 1 Uppercase 1 Numeric & 1 special character!";
        return obj;
    }
    return obj;
}
