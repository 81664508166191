import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { changePasswordData } from '../../../store/actions/AuthActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ChangePassword = (props) => {

    const dispatch = useDispatch();

    let errorsObj = { current: '', new: '', confirm: '' };

    const [errors, setErrors] = useState(errorsObj);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [current, setCurrent] = useState(false);
    const [old, setOld] = useState(false);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {

    }, []);

    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (currentPassword === '') {
            errorObj.current = 'Current password is Required';
            error = true;
        }
        if (newPassword === '') {
            errorObj.new = 'New password is Required';
            error = true;
        }
        if (confirmPassword === '') {
            errorObj.confirm = 'Confirm password is Required';
            error = true;
        }
        if (newPassword !== confirmPassword) {
            errorObj.confirm = 'New password confirm password does not match!';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(changePasswordData(currentPassword, newPassword, props.history));
    }


    return (
        <Fragment>
            <div className="card card-body">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                        <div className="profile-container">
                            <div className="profile-form-content">

                                <form onSubmit={onSubmit}>
                                    <div className="form-group mb-3">
                                        <label className="mb-2">
                                            <strong>Current Password</strong>
                                        </label>
                                        <div className='password-form-view'>
                                            <input type={current ? 'text' : 'password'} className="form-control" placeholder="" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                                            <button type='button' onClick={() => setCurrent(!current)}>
                                                {current ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i class="fa fa-eye" aria-hidden="true"></i>}</button>
                                        </div>
                                        {errors.current && <div className="text-danger fs-12">{errors.current}</div>}
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="mb-2">
                                            <strong>New Password</strong>
                                        </label>
                                        <div className='password-form-view'>
                                            <input type={old ? 'text' : 'password'} className="form-control" placeholder="" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                            <button type='button' onClick={() => setOld(!old)}>
                                                {old ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i class="fa fa-eye" aria-hidden="true"></i>}</button>
                                        </div>
                                        {errors.new && <div className="text-danger fs-12">{errors.new}</div>}
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="mb-2">
                                            <strong>Confirm Password</strong>
                                        </label>
                                        <div className='password-form-view'>
                                            <input type={confirm ? 'text' : 'password'} className="form-control" placeholder="" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                            <button type='button' onClick={() => setConfirm(!confirm)}>
                                                {confirm ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i class="fa fa-eye" aria-hidden="true"></i>}</button>
                                        </div>
                                        {errors.confirm && <div className="text-danger fs-12">{errors.confirm}</div>}
                                    </div>

                                    <div className="text-center mt-4">
                                        <button type="submit" className="btn btn-primary btn-block profile-update-btn">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: false,
        email: state.auth.email,
        user_name: state.auth.user_name,
        phone_no: state.auth.phone_no,
        profile_pic: state.auth.profile_pic,
    };
};

export default connect(mapStateToProps)(ChangePassword);