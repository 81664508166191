class GlobalConfigs {
  get VERSION() {
    return "1";
  }
  get API_URL() {
    return process.env.REACT_APP_APIHOSTURL
  }
  get CONCEPT_MAP_TEMPLATE_EXCEL_URL()
  {
    return process.env.REACT_APP_CONCEPT_MAP_TEMPLATE
  }
  get DOWNLOAD_BASE(){
    return process.env.REACT_APP_DOWNLOAD_CONCEPT_MAP_BASE
  }
  get EXCEL_SAMPLE_QUESTIONS()
  {
    return process.env.REACT_APP_SAMPLE_QUESTIONS
  }
  get SAMPLE_CSS()
  {
    return process.env.REACT_APP_SAMPLE_CSS
  }
  get TINY_API_KEY()
  {
    return "o502yzmshy174px1yuyq4z522zvqtxsuhzp7bmeyx2580hs9"
  }
}

const globalConfigs = new GlobalConfigs();

export default globalConfigs;
