import { GET_EARNING_LIST } from "../constants";

const initialState = {
  list: [],
  totalRecord: 0,
  totalPage: 0,
  totalEarning:0,
  showLoading: true,
};

export function EarningReducer(state = initialState, action) {
  if (action.type === GET_EARNING_LIST) {
    return {
      ...state,
      list: action.payload.data,
      totalRecord: action.payload.total_record,
      totalPage: action.payload.page_count,
      totalEarning:action.payload.total_earning,
      showLoading: false,
    };
  }

  return state;
}
