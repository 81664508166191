import React, { useState, useEffect,useRef } from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';
import LogoutPage from './Logout';
import avatar from "../../../images/egucation-girl.png";
import defoult_profile from "../../../images/demo-user.png";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getNotificationDetailsData,getReadNotificationData } from "../../../store/actions/AuthActions";

const NotificationBlog = ({ classChange }) => {

	
	return (
		<>
			<li>
				<div className="timeline-panel">
					<div className="media me-2">
						<img alt="images" width={50} src={avatar} />
					</div>
					<div className="media-body">
						<h6 className="mb-1">Dr sultads Send you Photo</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
			<li>
				<div className="timeline-panel">
					<div className={`media me-2 ${classChange}`}>KG</div>
					<div className="media-body">
						<h6 className="mb-1">Resport created successfully</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
			<li>
				<div className="timeline-panel">
					<div className={`media me-2 ${classChange}`}><i className="fa fa-home" /></div>
					<div className="media-body">
						<h6 className="mb-1">Reminder : Treatment Time!</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
		</>
	)
}

const Header = ({ onNote }) => {

	const dispatch = useDispatch();
	//For fix header
	const [headerFix, setheaderFix] = useState(false);

	const user_name = useSelector((state) => state.auth.user_name);
	const profileImage = useSelector(state => state.auth.profile_pic);

	const notificationRef = useRef();

	const notificationDetails = useSelector(state => state.auth.notificationDetails);

	//const notificationDetails =[]

	

	useEffect(() => {
		window.addEventListener("scroll", () => { setheaderFix(window.scrollY > 50); });

		dispatch(getNotificationDetailsData());
	}, []);

	const readNotification = (item) => {
		notificationRef.current.click();
		dispatch(globalLoadingAction(true));
		dispatch(getReadNotificationData(item));
	}


	//const [searchBut, setSearchBut] = useState(false);	
	var path = window.location.pathname.split("/");
	var name = path[path.length - 1].split("-");
	var filterName = name.length >= 4 ? name.filter((n, i) => i > 0) : name;
	var finalName = filterName.includes("app")
		? filterName.filter((f) => f !== "app")
		: filterName.includes("ui")
			? filterName.filter((f) => f !== "ui")
			: filterName.includes("uc")
				? filterName.filter((f) => f !== "uc")
				: filterName.includes("basic")
					? filterName.filter((f) => f !== "basic")
					: filterName.includes("jquery")
						? filterName.filter((f) => f !== "jquery")
						: filterName.includes("table")
							? filterName.filter((f) => f !== "table")
							: filterName.includes("page")
								? filterName.filter((f) => f !== "page")
								: filterName.includes("email")
									? filterName.filter((f) => f !== "email")
									: filterName.includes("ecom")
										? filterName.filter((f) => f !== "ecom")
										: filterName.includes("chart")
											? filterName.filter((f) => f !== "chart")
											: filterName.includes("editor")
												? filterName.filter((f) => f !== "editor")
												: filterName;
	return (
		<div className={`header ${headerFix ? "is-fixed" : ""}`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div
								className="dashboard_bar"
								style={{ textTransform: "capitalize" }}
							>
								{finalName.join(" ").length === 0
									? "Dashboard"
									: finalName.join(" ") === "dashboard dark"
										? "Dashboard"
										: finalName.join(" ")}
							</div>
						</div>
						<div className="navbar-nav header-right">

							<div className="dlab-side-menu">
								<div className="search-coundry d-flex align-items-center">
								</div>
								<div className="sidebar-social-link ">
									<ul className="">
										<Dropdown ref={notificationRef} as="li" className="nav-item dropdown notification_dropdown" >
										<Dropdown.Toggle className="nav-link i-false c-pointer notification_parent" variant="" as="a"  >
												<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" clipRule="evenodd" d="M18.7071 8.56414C18.7071 9.74035 19.039 10.4336 19.7695 11.2325C20.3231 11.8211 20.5 12.5766 20.5 13.3963C20.5 14.215 20.2128 14.9923 19.6373 15.6233C18.884 16.3798 17.8215 16.8627 16.7372 16.9466C15.1659 17.0721 13.5937 17.1777 12.0005 17.1777C10.4063 17.1777 8.83505 17.1145 7.26375 16.9466C6.17846 16.8627 5.11602 16.3798 4.36367 15.6233C3.78822 14.9923 3.5 14.215 3.5 13.3963C3.5 12.5766 3.6779 11.8211 4.23049 11.2325C4.98384 10.4336 5.29392 9.74035 5.29392 8.56414V8.16515C5.29392 6.58996 5.71333 5.55995 6.577 4.55164C7.86106 3.08114 9.91935 2.19922 11.9558 2.19922H12.0452C14.1254 2.19922 16.2502 3.12359 17.5125 4.65728C18.3314 5.64484 18.7071 6.63146 18.7071 8.16515V8.56414ZM9.07367 19.1136C9.07367 18.642 9.53582 18.426 9.96318 18.3336C10.4631 18.2345 13.5093 18.2345 14.0092 18.3336C14.4366 18.426 14.8987 18.642 14.8987 19.1136C14.8738 19.5626 14.5926 19.9606 14.204 20.2134C13.7001 20.5813 13.1088 20.8143 12.4906 20.8982C12.1487 20.9397 11.8128 20.9407 11.4828 20.8982C10.8636 20.8143 10.2723 20.5813 9.76938 20.2125C9.37978 19.9606 9.09852 19.5626 9.07367 19.1136Z" fill="#130F26" />
												</svg>
												{notificationDetails.length >= 100 ?
													<span className="notification_counter">99+</span>
													: notificationDetails.length == 0 ? null :
														<span className="notification_counter">{notificationDetails.length}</span>
												}
											</Dropdown.Toggle>
											<Dropdown.Menu align="right" className={`mt-2 dropdown-menu dropdown-menu-end`}>
												<PerfectScrollbar className={`widget-media dlab-scroll p-3 height380 ${notificationDetails == '' ? "no_notification_continer" : null}`}>
													<ul className="timeline">
														{/* <NotificationBlog classChange='media-info' notificationDetails /> */}
														{/* <NotificationBlog classChange='media-success' /> */}
														{/* <NotificationBlog classChange='media-danger' /> */}
														{/* <NotificationBlog classChange='media-info' /> */}
														{notificationDetails != '' && notificationDetails != undefined ?
															notificationDetails.map((item, index) => {
																return (
																	<li key={index} className="cursorPointer" onClick={() => readNotification(item)}>
																		<div className="timeline-panel">
																			{item.profile_pic == '' ?
																				<div className={`media me-2 media-info text-capitalize`}>{item.name.split('')[0]}</div>
																				:
																				<div className="media me-2">
																					<img alt="images" width={50} src={item.profile_pic} />
																				</div>
																			}
																			<div className="media-body">
																				<h6 className="mb-1 text-capitalize">{item.name}</h6>
																				<small className="d-block">{<Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment>}</small>
																				<small className="d-block">{item.notification_msg}</small>
																			</div>
																		</div>
																	</li>
																)
															})
															: <div className="no_notification">No New Notification</div>
														}

													</ul>
													<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
														<div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
													</div>
													<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
														<div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }} />
													</div>
												</PerfectScrollbar>
												{/* <Link className="all-notification" to="#">
													See all notifications <i className="ti-arrow-right" />
												</Link> */}
											</Dropdown.Menu>
										</Dropdown>
									</ul>
								</div>
								<ul>
									<Dropdown as="li" className="nav-item dropdown header-profile">
										<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer flex-column">
											<div>
												<img src={profileImage ? profileImage : defoult_profile} width={20} alt="" />
											</div>
											<div>
												<p className="user_name flex-wrap d-flex">{user_name}</p>
											</div>
										</Dropdown.Toggle>

										<Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-end">
											<Link to="/profile" className="dropdown-item ai-icon">
												<svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1" width={18} height={18} viewBox="0 0 24 24" fill="none"
													stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
												>
													<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
													<circle cx={12} cy={7} r={4} />
												</svg>
												<span className="ms-2">Profile </span>
											</Link>

											<Link to="/change-password" className="dropdown-item ai-icon">
												<i class="fa fa-unlock-alt" aria-hidden="true"></i>
												<span className="ms-3">Change Password</span>
											</Link>
											<LogoutPage />
										</Dropdown.Menu>
									</Dropdown>
								</ul>

							</div>

						</div>


					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;