import {
    LOADING_EXAMTYPE,
    GET_EXAMTYPE_LIST
} from '../constants'

const initialState = {
    examTypeList: [],
    showLoading: false,
};

export function ExamTypeReducer(state = initialState, action) {

    if (action.type === GET_EXAMTYPE_LIST) {
        return {
            ...state,
            examTypeList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === LOADING_EXAMTYPE) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


