import {
    LOGIN_CONFIRMED,
    LOADING_TOGGLE,
    LOGIN_FAILED,
    LOGOUT,
    UPDATE_PROFILE,
    SIGNUP_CONFIRMED,
    SIGNUP_FAILED,
    NOTIFICATION_DETAILS
} from '../constants';

const initialState = {
    user_id: '',
    token: '',
    email: '',
    user_name: '',
    phone_no: '',
    developer_type: '',
    developerType: null,
    profile_pic: '',
    notificationDetails: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    if (action.type === LOGIN_CONFIRMED) {
        return {
            ...state,
            user_id: action.payload.id,
            token: action.payload.token,
            email: action.payload.email,
            user_name: action.payload.name,
            phone_no: action.payload.phone,
            profile_pic: action.payload.profile_pic,
            developer_type: action.payload.developer_type_name,
            developerType:action.payload.developer_type,
            showLoading: false,
        };
    }
    if (action.type === UPDATE_PROFILE) {
        return {
            ...state,
            profile_pic: action.payload.profile_pic,
            user_name: action.payload.name,
            phone_no: action.payload.phone,
            email: action.payload.email,
            showLoading: false,
        };
    }
    if (action.type === LOGOUT) {
        return {
            ...state,
            user_id: '',
            token: '',
            email: '',
            user_name: '',
            phone_no: '',
            profile_pic: '',
            developer_type: '',
            developerType:null,
        };
    }

    if (
        action.type === SIGNUP_FAILED ||
        action.type === LOGIN_FAILED
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    if (action.type === NOTIFICATION_DETAILS) {
        return {
            ...state,
            notificationDetails: action.payload,
            showLoading: false,
        };
    }

    return state;
}


