import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function getSubjectList(category_id, exam_type_id, board_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        category_id, 
        exam_type_id, 
        board_id
    }
    console.log("^^^^^^---", postData)
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_GET_SUBJECT_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}



