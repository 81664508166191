import {
    GET_EXAM_CATEGORY_LIST,
    LOADING_CATEGORY
} from '../constants';

const initialState = {
    categoryList: [],
    showLoading: false,
};

export function ExamCategoryReducer(state = initialState, action) {

    if (action.type === GET_EXAM_CATEGORY_LIST) {
        return {
            ...state,
            categoryList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === LOADING_CATEGORY) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


