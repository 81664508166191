import {
    LOADING_QUESTION_TYPE,
    GET_QUESTION_TYPE_LIST
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function QuestionTypeReducer(state = initialState, action) {

    if (action.type === GET_QUESTION_TYPE_LIST) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === LOADING_QUESTION_TYPE) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


