//////////////GLOBAL_LOADING//////////

export const GLOBAL_LOADING = "GLOBAL_LOADING";

//////////////Auth//////////

export const LOGIN_CONFIRMED = "LOGIN_CONFIRMED";

export const LOADING_TOGGLE = "LOADING_TOGGLE";

export const NOTIFICATION_DETAILS = "NOTIFICATION_DETAILS";

export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT = "LOGOUT";

export const SIGNUP_CONFIRMED = "SIGNUP_CONFIRMED";

export const SIGNUP_FAILED = "SIGNUP_FAILED";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

//////////////Account-verifi//////////

export const ACCOUNT_VERIFI = "ACCOUNT_VERIFI";

//////////////Question-content//////////

export const UPLOAD_QUESTIONS = "UPLOAD_QUESTIONS";

export const GET_QUESTIONS_LIST = "GET_QUESTIONS_LIST";

export const GET_TOTAL_QUESTIONS_PAGE_LIST = "GET_TOTAL_QUESTIONS_PAGE_LIST";

export const QUESTIONS_CONTENT_LOADING = "QUESTIONS_CONTENT_LOADING";

//////////////Exam-category-List//////////

export const LOADING_CATEGORY = "LOADING_CATEGORY";

export const GET_EXAM_CATEGORY_LIST = "GET_EXAM_CATEGORY_LIST";

//////////////Branch-List//////////

export const GET_BRANCH_LIST = "GET_BRANCH_LIST";

//////////////Chapter-List//////////

export const LOADING_CHAPTER = "LOADING_CHAPTER";

export const GET_CHAPTER_LIST = "GET_CHAPTER_LIST";

//////////////Class-List//////////

export const LOADING_CLASS = "LOADING_CLASS";

export const GET_CLASS_LIST = "GET_CLASS_LIST";

//////////////E-Library//////////

export const GET_ELIBRARY_CONTENT_LIST = "GET_ELIBRARY_CONTENT_LIST";

export const APPROVED_REJECT_ELIBRARY_CONTENT_STATUS = "APPROVED_REJECT_ELIBRARY_CONTENT_STATUS";

export const UPLOAD_CONTENT_DESIGNER_PDF_ELIBRARY = "UPLOAD_CONTENT_DESIGNER_PDF_ELIBRARY";

export const ELIBRARY_CONTENT_REJECT_REASONS = "ELIBRARY_CONTENT_REJECT_REASONS";

export const ELIBRARY_LOADING = "ELIBRARY_LOADING";

//////////////Board-List//////////

export const LOADING_BOARD = "LOADING_BOARD";

export const GET_BOARD_LIST = "GET_BOARD_LIST";

//////////////ExamType//////////

export const LOADING_EXAMTYPE = "LOADING_EXAMTYPE";

export const GET_EXAMTYPE_LIST = "GET_EXAMTYPE_LIST";

//////////////Subject//////////

export const LOADING_SUBJECT = "LOADING_SUBJECT";

export const GET_SUBJECT_LIST = "GET_SUBJECT_LIST";

//////////////E-library//////////

export const E_LIBRARY_LIST = "E_LIBRARY_LIST";

//////////////Questions//////////

export const QUESTIOS_LOADING = "QUESTIOS_LOADING";

export const GET_QUESTION_CONTENT_LIST = "GET_QUESTION_CONTENT_LIST";

export const GET_QUESTION_REQUEST_DETAILS_LIST = "GET_QUESTION_REQUEST_DETAILS_LIST";

export const UPLOAD_QUESTION_BY_DEVELOPER_FILE = "UPLOAD_QUESTION_BY_DEVELOPER_FILE";

export const GET_QUESTION_DETAILS_BY_ID = "GET_QUESTION_DETAILS_BY_ID";

//////////////Questions Type//////////

export const GET_QUESTION_TYPE_LIST = "GET_QUESTION_TYPE_LIST";

export const LOADING_QUESTION_TYPE = "LOADING_QUESTION_TYPE";

//////////////My Earning////////////

export const GET_EARNING_LIST = "GET_EARNING_LIST";




