/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
	componentWillUnmount() {
	}
	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

const SideBar = () => {
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);

	useEffect(() => {

		var btn = document.querySelector(".nav-control");
		var aaa = document.querySelector("#main-wrapper");
		function toggleFunc() {
			return aaa.classList.toggle("menu-toggle");
		}
		btn.addEventListener("click", toggleFunc);

	}, []);
	//For scroll

	const [hideOnScroll, setHideOnScroll] = useState(true);

	useScrollPosition(
		({ prevPos, currPos }) => {
			const isShow = currPos.y > prevPos.y
			if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
	/// Path
	let path = window.location.pathname;
	path = path.split("/");
	path = path[path.length - 1];

	/// Active menu
	let deshBoard = [
		"dashboard",
		"profile",
		"change-password"
	];
	let elibrary = [
		"upload-content",
		"content-list",
		"elibrary-content-detail"
	];
	let question = [
		"questions-list",
		"view-question-details",
		"question-request-details",
		"upload-questions",
		"update-questions"
	]
	let myEarning=[
		"my-earning"
	]

	return (
		<div
			className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
				sidebarLayout.value === "horizontal" &&
				headerposition.value === "static"
				? hideOnScroll > 120
					? "fixed"
					: ""
				: ""
				}`}
		>
			<PerfectScrollbar className="dlabnav-scroll">
				<MM className="metismenu" id="menu">
					<li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
						<Link to="dashboard">
							<i className="bi bi-grid"></i>
							<span className="nav-text">Dashboard</span>
						</Link>
					</li>

					<li className={`${elibrary.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#" >
							<i className="bi bi-book"></i>
							<span className="nav-text">e-Library</span>
						</Link>
						<ul>
							<li><Link className={`${path === "content-list" ? "mm-active" : ""}`} to="/content-list">Content list</Link></li>
						</ul>
					</li>

					<li className={`${question.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#" >
							<i className="bi bi-question-square"></i>
							<span className="nav-text">Questions</span>
						</Link>
						<ul>
							<li><Link className={`${path === "questions-list" ? "mm-active" : ""}`} to="/questions-list">Questions List</Link></li>
						</ul>
					</li>

					<li className={`${myEarning.includes(path) ? "mm-active" : ""}`}>
						<Link to="my-earning">
							<i className="bi bi-wallet"></i>
							<span className="nav-text">My Earning</span>
						</Link>
					</li>

				</MM>
				<div className="copyright mt-5">
					<p>Crestest Learning Ventures LLP © 2023 All Rights Reserved</p>
				</div>
			</PerfectScrollbar>
		</div>
	);
};

export default SideBar;
