import swal from "sweetalert";
import {
    getCategoty,
} from '../../services/ExamCategoryServices';

import {
    LOADING_CATEGORY,
    GET_EXAM_CATEGORY_LIST,
} from '../constants';

import * as utility from '../../utility/Utility';


export function getExamCategory(history) {
    return (dispatch) => {
        getCategoty()
            .then((response) => {
                dispatch(getCategotyAction(response.data.data));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getCategotyAction(data) {
    return {
        type: GET_EXAM_CATEGORY_LIST,
        payload: data,
    };
}

export function loadingCategotyAction(status) {
    return {
        type: LOADING_CATEGORY,
        payload: status,
    };
}