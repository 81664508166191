import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from 'react-bootstrap';
import * as utility from '../../../utility/Utility';

//images
import Educat from './../../../images/egucation-girl.png';
import { checkVerifi } from '../../../store/actions/CheckVerifiAction';

const Home = () => {

	const dispatch = useDispatch();

	const user_name = useSelector((state) => state.auth.user_name);
	const developer_type = useSelector((state) => state.auth.developer_type);

	useEffect(() => {
		dispatch(checkVerifi());
	}, []);

	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="card height justify-content-center align-items-center">
						<div>
							<h2>{user_name}</h2>
						</div>
						<div>
							<h4>{developer_type}</h4>
						</div>

					</div>
				</div>
			</div>
		</>
	)
}
export default Home;