import swal from "sweetalert";
import {
    checkVerifiAccount
} from '../../services/CheckVerifiServices';

import {
    ACCOUNT_VERIFI
} from '../constants';

import * as utility from '../../utility/Utility';


export function checkVerifi(file, history) {
    return (dispatch) => {
        checkVerifiAccount(file)
            .then((response) => {
                dispatch(checkVerifiAction(response.data.status));
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function checkVerifiAction(data) {
    return {
        type: ACCOUNT_VERIFI,
        payload: data,
    };
}
// export function loadingCheckVerifiAction(status) {
//     return {
//         type: QUESTIONS_CONTENT_LOADING,
//         payload: status,
//     };
// }
