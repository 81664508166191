import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import { BsJournalMedical } from "react-icons/bs";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  getErningListData,
  paymentTransactionDetailsList,
} from "../../../store/actions/EarningAction";
import {
  getExamCategory,
  loadingCategotyAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getBoardDetails,
  loadingBoard,
} from "../../../store/actions/BoardActions";
import {
  getExamTypeDetails,
  loadingExamType,
} from "../../../store/actions/ExamTypeActions";
import {
  getClassData,
  getClassAction,
  loadingClass,
} from "../../../store/actions/ClassAction";
import {
  getSubjectListDetails,
  getSubjectListAction,
  loadingSubject,
} from "../../../store/actions/SubjectActions";
import {
  getChaptersData,
  getChapterAction,
  loadingChapter,
} from "../../../store/actions/ChapterAction";
import sort from "../../../images/sort.png";
import statusText from "../statusList";

const MyEarning = (props) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(1);
  const [contentType, setContentType] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [category, setCategory] = useState("");
  const [exmtype, setExmtype] = useState("");
  const [board, setBoard] = useState("");
  const [classRoom, setClassRoom] = useState("");
  const [subject, setSubject] = useState("");
  const [chapter, setChapter] = useState("");
  const [transId, setTransId] = useState("");
  const [transctionDetails, setTransctionDetails] = useState([]);
  const [filterObj, setFilterObj] = useState({
    contentType: "",
    transactionStatus: "",
    category: "",
    board: "",
    exmtype: "",
    classRoom: "",
    subject: "",
    chapter: "",
    transId: "",
  });
  const [isModal, setIsModal] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    dispatch(getExamCategory(props.history));
    dispatch(getBoardDetails(props.history));
    dispatch(getExamTypeDetails(2, props.history));
  }, []);

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getErningListData(
        contentType,
        transactionStatus,
        category,
        category == 1 ? board : "",
        category == 2 ? exmtype : "",
        classRoom,
        subject,
        chapter,
        transId,
        active,
        props.history
      )
    );
  }, []);

  const onFilterHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getErningListData(
        contentType,
        transactionStatus,
        category,
        category == 1 ? board : "",
        category == 2 ? exmtype : "",
        classRoom,
        subject,
        chapter,
        transId,
        1,
        props.history
      )
    );
    setActive(1);
    setIsFilter(false);
    setFilterObj((prev) => ({
      ...prev,
      contentType,
      transactionStatus,
      category,
      board,
      exmtype,
      classRoom,
      subject,
      chapter,
      transId,
    }));
  };

  const onResetHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getErningListData("", "", "", "", "", "", "", "", "", 1, props.history)
    );
    setIsFilter(false);
    setActive(1);
    setContentType("");
    setTransactionStatus("");
    setCategory("");
    setExmtype("");
    setBoard("");
    setClassRoom("");
    setSubject("");
    setChapter("");
    setTransId("");
    setFilterObj((prev) => ({
      ...prev,
      contentType: "",
      transactionStatus: "",
      category: "",
      board: "",
      exmtype: "",
      classRoom: "",
      subject: "",
      chapter: "",
      transId: "",
    }));
  };

  const handlePageClick = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getErningListData(
        contentType,
        transactionStatus,
        category,
        category == 1 ? board : "",
        category == 2 ? exmtype : "",
        classRoom,
        subject,
        chapter,
        transId,
        data.selected + 1,
        props.history
      )
    );
  };

  const getContentDetails = (data) => {
    return data.map((element, index) => (
      <p className="line-height-08" key={index}>
        {element.type}: {element.value}
      </p>
    ));
  };

  const getTransDetails = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((element, index) => (
        <p className="line-height-08" key={index}>
          {element}
        </p>
      ));
    }
    return  <p className="text-center">-</p>;
  };

  const onChangeCategoryHandler = (val) => {
    setCategory(val);
    setExmtype("");
    setBoard("");
    setClassRoom("");
    setSubject("");
    setChapter("");
  };

  const boardTypeChangeHandler = (val) => {
    dispatch(getClassData(category, props.history));
    if (category == 1) {
      setBoard(val);
      setExmtype("");
    } else {
      setExmtype(val);
      setBoard("");
    }
    setClassRoom("");
    setSubject("");
    setChapter("");
  };

  const onChangeClassHandler = (val) => {
    setClassRoom(val);
    setSubject("");
    setChapter("");
  };

  const getSubjectHandler = () => {
    dispatch(loadingSubject(true));
    dispatch(
      getSubjectListDetails(
        category,
        category == 2 ? exmtype : 0,
        category == 1 ? board : 0,
        props.history
      )
    );
  };

  const onChangeSubjectHandler = (val) => {
    setSubject(val);
    setChapter("");
    dispatch(getChaptersData(val, classRoom ? classRoom : 0, props.history));
  };

  const onChangeTransIdHandler = (val) => {
    setTransId(val);
  };

  const getTransactionDetails = (item) => {
    setIsModal(true);
    dispatch(globalLoadingAction(true));
    dispatch(
      paymentTransactionDetailsList(
        item.id,
        getTransactionDetailsCallBack,
        props.history
      )
    );
  };

  const getTransactionDetailsCallBack = (item) => {
    setTransctionDetails(item.data);
    setIsModal(true);
  };

  const isDisabled =
    contentType ||
    transactionStatus ||
    category ||
    board ||
    exmtype ||
    classRoom ||
    subject ||
    chapter ||
    transId;

  const enableFilter =
    filterObj.contentType ||
    filterObj.transactionStatus ||
    filterObj.category ||
    filterObj.board ||
    filterObj.exmtype ||
    filterObj.classRoom ||
    filterObj.subject ||
    filterObj.chapter ||
    filterObj.transId;

  const getCount = (val) => {
    let record = (active - 1) * 10 + val;
    return record;
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Row>
                <div className="earning-container mb-3">
                  <h3>
                    <span>RS.</span>
                    {props.totalEarning}
                  </h3>
                  <h4>Total Earning</h4>
                </div>
              </Row>
              <div className="contentlist-filter-view mb-3">
                <div className="reset-btn-view">
                  <div
                    className={`filter_btn mb-3 ${
                      enableFilter ? "border_red" : null
                    }`}
                    onClick={() => setIsFilter(true)}
                  >
                    <div className="image_container">
                      <img src={sort} alt="" className="image_style" />
                    </div>
                    <div className="filter_btn_view">
                      <p>Filter</p>
                    </div>
                  </div>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="col_heading">
                      <strong>#</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Content Type</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Content Details</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Submitted Details</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Date</strong>
                    </th>
                    <th className="chapter_col col_heading">
                      <strong>Payment Details</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Transaction ID</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Trans Status</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                {props.list.length > 0 ? (
                  <tbody>
                    {props.list.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{getCount(index + 1)}</td>
                          <td>{item.content_type}</td>
                          <td>{getContentDetails(item.content_details)}</td>
                          <td>
                            {item.content_type == 'Questions'?
                            <>
                            <p>Requested Questions: {item.requested_question}</p>
                            <p>
                              Submitted Questions: {item.submitted_question}
                            </p>
                            <p>Approved {item.content_type == 'e-Library'?'Content':'Questions'}: {item.approved_question}</p>
                            </>
                            : <span className="text-center d-block">-</span>}
                          </td>
                          <td>
                            <p>Created Date: {item.created_date}</p>
                            <p>Submitted Date: {item.submited_date}</p>
                            <p>Approved Date: {item.approved_date}</p>
                          </td>
                          <td>
                            <p>Total Amount: {item.total_payment}</p>
                            <p>Paid Amount: {item.paid_amount}</p>
                            <p>Due Amount: {item.due_amount}</p>
                          </td>
                          <td>{getTransDetails(item.transction_id)}</td>
                          <td className="text-center">
                            {item.payment_status == 1 ? "Due" : "Paid"}
                          </td>
                          <td>
                            {
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>Details</Tooltip>
                                )}
                                placement="top"
                              >
                                <button
                                  className={`btn btn-secondary history_background shadow btn-xs sharp me-1`}
                                  onClick={() => getTransactionDetails(item)}
                                >
                                  <BsJournalMedical size={15} />
                                </button>
                              </OverlayTrigger>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={13}>
                        <div className="no_data">
                          <strong>No data found</strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              <div className="pagination-container">
                {parseInt(props.totalRecord) > 0 ? (
                  <div className="page-result">
                    <p>
                      Showing{" "}
                      {(active - 1) * 10 +
                        1 +
                        " - " +
                        ((active - 1) * 10 + props.list.length) +
                        " of " +
                        "( " +
                        props.totalRecord +
                        " items )"}
                    </p>
                  </div>
                ) : null}

                {parseInt(props.totalRecord) > 10 ? (
                  <ReactPaginate
                    containerClassName="pagination-gutter false pagination-circle pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLabel="..."
                    nextLabel={<i className="la la-angle-right"></i>}
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousLabel={<i className="la la-angle-left"></i>}
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={props.totalPage}
                    //initialPage={active - 1}
                    forcePage={active - 1}
                  />
                ) : null}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal className="fade bd-example-modal-lg" show={isModal} size="lg">
        <Modal.Header>
          <Modal.Title>Transaction Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th className="col_heading">
                  <strong>Transaction ID</strong>
                </th>
                <th className="col_heading">
                  <strong>Amount</strong>
                </th>
                <th className="col_heading">
                  <strong>Date</strong>
                </th>
              </tr>
            </thead>
            {transctionDetails.length > 0 ? (
              <tbody>
                {transctionDetails.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.transction_id}</td>
                      <td>{item.paid_amount}</td>
                      <td>
                        {item.created_at
                          ? moment(item.created_at).format("DD/MM/YYYY")
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
          </Table>
          {transctionDetails.length > 0 ? null : (
            <div className="mt-3">
              <h4 className="text-center">No Data Found</h4>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal className="fade" show={isFilter} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsFilter(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={!isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={contentType}
                    onChange={(e) => setContentType(e.target.value)}
                  >
                    <option value="">Select Content Type</option>
                    <option value="E">e-Library</option>
                    <option value="Q">Question</option>
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={transactionStatus}
                    onChange={(e) => setTransactionStatus(e.target.value)}
                  >
                    <option value="">Select Trans Status</option>
                    <option value="1">Due</option>
                    <option value="2">Paid</option>
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={category}
                    onChange={(e) => onChangeCategoryHandler(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {props.categoryList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.category}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${
                      category ? null : "content_disable"
                    }`}
                    value={category == 1 ? board : exmtype}
                    onChange={(e) => boardTypeChangeHandler(e.target.value)}
                  >
                    <option value="">
                      {category == 1
                        ? "Select Board"
                        : category == 2
                        ? "Select Type"
                        : "Select Board/Type"}
                    </option>
                    {category == 1
                      ? props?.boardList?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })
                      : props?.typeList?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.type_name}
                            </option>
                          );
                        })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${
                      category == 2 && exmtype == 1 ? "content_disable" : null
                    }`}
                    value={classRoom}
                    onChange={(e) => onChangeClassHandler(e.target.value)}
                  >
                    <option value="">Select Class</option>
                    {props.classList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.class_no}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    {props.loadingSubject ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <i className="bi bi-caret-down-fill"></i>
                    )}
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${
                      (board && classRoom) ||
                      exmtype == 1 ||
                      (classRoom && exmtype != 1)
                        ? null
                        : "content_disable"
                    }`}
                    value={subject}
                    onClick={getSubjectHandler}
                    onChange={(e) => onChangeSubjectHandler(e.target.value)}
                  >
                    <option value="">Select Subject</option>
                    {props.subjectList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    {props.chapterLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <i className="bi bi-caret-down-fill"></i>
                    )}
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${
                      subject ? null : "content_disable"
                    }`}
                    value={chapter}
                    onChange={(e) => setChapter(e.target.value)}
                  >
                    <option value="">Select Chapter Name</option>
                    {props.chapterList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.chapter_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group mb-3 col-sm-6 px-1">
                <input
                  className="form-control"
                  value={transId}
                  onChange={(e) => onChangeTransIdHandler(e.target.value)}
                  placeholder="Trans ID"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsFilter(false)} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={!isDisabled}
            onClick={() => onFilterHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.earning.showLoading,
    list: state.earning.list,
    totalEarning: state.earning.totalEarning,
    totalRecord: state.earning.totalRecord,
    totalPage: state.earning.totalPage,
    categoryList: state.category.categoryList,
    loadingCategory: state.category.showLoading,
    boardList: state.board.boardList,
    typeList: state.examtype.examTypeList,
    classList: state.class.classList,
    subjectList: state.subject.subjectList,
    chapterList: state.chapter.chapterList,
  };
};

export default connect(mapStateToProps)(MyEarning);
