import {
    GET_BRANCH_LIST
} from '../constants'

const initialState = {
    branchList: [],
    showLoading: false,
};

export function BranchReducer(state = initialState, action) {

    if (action.type === GET_BRANCH_LIST) {
        return {
            ...state,
            branchList: action.payload,
            showLoading: false,
        };
    }
    
    return state;
}


