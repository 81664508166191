
import {
    LOADING_BOARD,
    GET_BOARD_LIST,
} from '../constants';

import {
    getBoard,
} from '../../services/BoardService';

import * as utility from '../../utility/Utility';

export function getBoardDetails(history) {
    return (dispatch) => {
        getBoard()
            .then((response) => {
                dispatch(getBoardList(response.data.data));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getBoardList(data) {
    return {
        type: GET_BOARD_LIST,
        payload: data,
    };
}
export function loadingBoard(status) {
    return {
        type: LOADING_BOARD,
        payload: status,
    };
}