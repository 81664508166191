import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import {
	loadingToggleAction,
	signupAction,
} from '../../store/actions/AuthActions';
// image
import logo from "../../images/logo.png";

import { validateEmail, validatePhone } from "../../utility/Utility";

function Register(props) {

	let errorsObj = { name: '', email: '', usertype: '', phone: '', password: '', confirmPws: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [fullname, setFullname] = useState('');
	const [email, setEmail] = useState('');
	const [usertype, setUsertype] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPws, setConfirmPws] = useState('');

	const dispatch = useDispatch();

	function onSignUp(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (fullname === '') {
			errorObj.name = 'Fullname is Required';
			error = true;
		}
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		} else if (validateEmail(email)) {
			errorObj.email = 'Invalid email';
			error = true;
		}
		if (usertype === '') {
			errorObj.usertype = 'User type is Required';
			error = true;
		}
		if (phone === '') {
			errorObj.phone = 'Phone no. is Required';
			error = true;
		} else if (!validatePhone(phone)) {
			errorObj.phone = 'Invalid Phone no.';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		if (confirmPws === '') {
			errorObj.confirmPws = 'Confirm password is Required';
			error = true;
		} else if (confirmPws !== password) {
			errorObj.confirmPws = 'Confirm password is not match';
			error = true;
		}

		setErrors(errorObj);
		if (error) return;
		dispatch(loadingToggleAction(true));
		dispatch(signupAction(fullname, email, parseInt(usertype), phone, confirmPws, props.history));
		setFullname('');
		setEmail('');
		setUsertype('');
		setPhone('');
		setPassword('');
		setConfirmPws('');
	}
	return (
		<div className="authincation h-100 p-meddle">
			<div className="container h-100">
				<div className="row justify-content-center h-100 align-items-center">
					<div className="col-md-7">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form register-form">
										<div className="text-center mb-3">
											<Link to="/login">
												<img src={logo} alt="" />
											</Link>
										</div>
										<h4 className="text-center mb-4 ">Sign up your account</h4>
										<form onSubmit={onSignUp}>

											<div className="row">
												<div className="form-group mb-3 col-6 pr-5">
													<label className="mb-1 ">
														<strong>Full name</strong>
													</label>
													<input type="text" value={fullname} onChange={(e) => setFullname(e.target.value)} className="form-control" placeholder="Full name" />
													{errors.name && <div className="register-form-error">{errors.name}</div>}
												</div>
												<div className="form-group mb-3 col-6 pl-5">
													<label className="mb-1 ">
														<strong>Email</strong>
													</label>
													<input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
													{errors.email && <div className="register-form-error">{errors.email}</div>}
												</div>

											</div>
											<div className="row">

												<div className="form-group mb-3 col-6 pr-5">
													<label className="mb-1 ">
														<strong>User type</strong>
													</label>
													<select
														defaultValue={"option"}
														className="form-control"
														value={usertype}
														onChange={(e) => setUsertype(e.target.value)}
													>
														<option value="">Select user type</option>
														<option value='2'>Content Creator</option>
														<option value='3'>Content Validators</option>
														<option value='4'>Content Designer</option>
														<option value='5'>Content Language Validator</option>
													</select>
													{errors.usertype && <div className="register-form-error">{errors.usertype}</div>}
												</div>

												<div className="form-group mb-3 col-6 pl-5">
													<label className="mb-1 ">
														<strong>Phone No.</strong>
													</label>
													<input
														value={phone}
														onChange={(e) =>
															setPhone(e.target.value)
														}
														className="form-control"
														placeholder="Phone"
													/>
													{errors.phone && <div className="register-form-error">{errors.phone}</div>}
												</div>

											</div>

											<div className="row">

												<div className="form-group mb-3 col-6 pr-5">
													<label className="mb-1 ">
														<strong>Password</strong>
													</label>
													<input
														value={password}
														onChange={(e) =>
															setPassword(e.target.value)
														}
														className="form-control"
														type="password"
														placeholder="Password"
													/>
													{errors.password && <div className="register-form-error">{errors.password}</div>}
												</div>

												<div className="form-group mb-3 col-6 pl-5">
													<label className="mb-1 ">
														<strong>Confirm password</strong>
													</label>
													<input
														value={confirmPws}
														onChange={(e) =>
															setConfirmPws(e.target.value)
														}
														className="form-control"
														type="password"
														placeholder="Confirm Password"
													/>
													{errors.confirmPws && <div className="register-form-error">{errors.confirmPws}</div>}
												</div>

											</div>

											<div className="text-center mt-4">
												<button type="submit" className="btn btn-primary btn-block btn-login">Sign me up
													{props.showLoading ?
														<Spinner
															as="span"
															animation="border"
															size="sm"
															role="status"
															aria-hidden="true"
														/>
														: null}
												</button>
											</div>
										</form>
										<div className="new-account text-center mt-3">
											<p className="">
												Already have an account?{" "}
												<Link className="text-primary" to="/login">
													Sign in
												</Link>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		showLoading: state.auth.showLoading,
	};
};

export default connect(mapStateToProps)(Register);

