import {
    UPLOAD_QUESTIONS,
    GET_QUESTIONS_LIST,
    GET_TOTAL_QUESTIONS_PAGE_LIST,
    QUESTIONS_CONTENT_LOADING
} from '../constants';

const initialState = {
    list: [],
    totalPage: 0,
    showLoading: false,
};

export function QuestionsReducer(state = initialState, action) {

    if (action.type === UPLOAD_QUESTIONS) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_QUESTIONS_LIST) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }
    if (action.type === GET_TOTAL_QUESTIONS_PAGE_LIST) {
        return {
            ...state,
            totalPage: action.payload,
            showLoading: false,
        };
    }

    if (action.type === QUESTIONS_CONTENT_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


