import swal from "sweetalert";
import {
    getClass,
} from '../../services/ClassServices';

import {
    LOADING_CLASS,
    GET_CLASS_LIST,
} from '../constants';

import * as utility from '../../utility/Utility';


export function getClassData(history) {
    return (dispatch) => {
        getClass()
            .then((response) => {
                dispatch(getClassAction(response.data.data));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getClassAction(data) {
    return {
        type: GET_CLASS_LIST,
        payload: data,
    };
}

export function loadingClass(status) {
    return {
        type: LOADING_CLASS,
        payload: status,
    };
}